<template>
  <v-dialog v-model="citationDocumentDialog" width="500">
    <v-card>
      <v-card-title> Desea exportar el documento ? </v-card-title>
      <v-card-text class="pt-2 pb-2">
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded color="dark" @click="citationDocumentDialog=false">Cancelar</v-btn>
        <v-btn rounded color="primary"  @click="getDocument()">Exportar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import citationDocument from "@/utils/citationDocument";
import {getHeaderLines} from "@/services/SelfJustice";
import Swal from "sweetalert2";

export default {
  name: "CitationDocumentDialog",
  data() {
    return {
      citationDocumentDialog: false,
      citationData: {},
      authoritiesSelected: [],
    };
  },
  methods: {
    open(data) {
      this.citationData = data;
      this.citationDocumentDialog = true;
    },
    async getDocument() {
      try{
        this.showLoader();
        const response = await getHeaderLines(this.citationData.territory.id);
        citationDocument(
            this.citationData.citation,
            this.citationData.involved,
            this.authoritiesSelected,
            response.data.map(x => x.line)
        );
        this.hideLoader();
      }catch (e) {
        this.hideLoader()
        console.error(e);
        Swal.fire('Error', 'Ha ocurrido un error', 'error')
      }

    },
  },
  computed: {
    ...mapState(["selfJusticeReference"]),
  },
};
</script>

<style scoped></style>
