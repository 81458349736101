import { jsPDF } from "jspdf";

const centeredText = (text, y, doc) => {
    const textWidth =
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
    const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, y, text);
};


const makeHeader = (lines, startPos, doc) => {
    const lineHeight = 4;
    lines.forEach((line) => {
        centeredText(line, startPos, doc);
        startPos += lineHeight;
    });
};


function multicell(doc, text, x, y, w) {
    const lines = doc.splitTextToSize(text, w);
    doc.text(x, y, lines);
}

// capture order document
export default function captureOrderDocument(news, captureOrder, territory, involved, authorities, headers = []) {
    const doc = new jsPDF();
    const x_default_pos = 20;
    doc.setFont("Helvetica");
    doc.setFontSize(8);

    const headerLines = headers;

    makeHeader(headerLines, 10, doc);

    doc.setFontSize(14);

    centeredText(`ORDEN DE CAPTURA No. ${captureOrder.id}`, 35, doc);

    doc.setFontSize(10);
    const mainText = `Siendo ${news.date}, la Autoridad indígena de ${territory.descripcion} en uso de las facultades legales que le confiere la Palabra de Origen y Los Mandatos Comunitarios del   Derecho Propio, la Ley 89 de 1890, los artículos 7 y 246 de la Constitución Política de Colombia y demás normas nacionales e internacionales de para pueblos indígenas.`
    const splitText = doc.splitTextToSize(mainText, 180);
    doc.text(x_default_pos, 45, splitText);

    doc.setFontSize(14);
    centeredText(`PROCEDEN`,65, doc);
    doc.setFontSize(10);
    const captureOrderData = `Dictan orden de captura al comunero(a): ${involved.name} ${involved.second_name} ${involved.lastname} ${involved.second_surname} identificado con cedula de ciudadanía No. ${involved.document_number} de ${captureOrder.expedition_place}, Cauca, perteneciente al territorio indígena de ${involved.territory.descripcion}. por ${news.description}.`;
    const splitText2 = doc.splitTextToSize(captureOrderData, 180);
    doc.text(x_default_pos, 75, splitText2);

    const requestText = `Se solicita a las Autoridades Indígenas y autoridades competentes del Estado Colombiano, hacer efectiva esta orden judicial emitida por esta Autoridad Indígena ${territory.descripcion}.`;
    const splitText3 = doc.splitTextToSize(requestText, 180);
    doc.text(x_default_pos, 95, splitText3);

    // add image to document center

    const imageOffset = (doc.internal.pageSize.width - 50) / 2;
    doc.addImage(
        "data:image/png;base64," + captureOrder.involved_photo,
        "PNG",
        imageOffset,
        105,
        50,
        50
    );

    doc.setFontSize(14);
    centeredText(`CARACTERISTICAS FISICAS`,165, doc);
    doc.setFontSize(10);

    multicell(doc, captureOrder.physical_characteristics, x_default_pos, 172, 180);
    doc.setFontSize(14);
    centeredText(`AUTORIDADES FIRMANTES`,190, doc);
    doc.setFontSize(10);
    let startPos = 205;

    const signatures = [{
        name : 'Nombre Autoridad',
        document : 'CC. No.',
        signature : 'Firma'
    },
        {
            name : 'Nombre Autoridad',
            document : 'CC. No.',
            signature : 'Firma'
        },
        {
            name : 'Nombre Autoridad',
            document : 'CC. No.',
            signature : 'Firma'
        }
    ];

    signatures.forEach((line) => {
        //calculate page break
        if (startPos + 30 > doc.internal.pageSize.height) {
            doc.addPage();
            startPos = 20;
        }

        doc.text(line.signature, x_default_pos, startPos);
        doc.line(x_default_pos + 15, startPos, 120, startPos);
        doc.text(line.name, x_default_pos, startPos + 6);
        doc.line(x_default_pos + 35, startPos + 6, 120, startPos + 6);
        doc.text(line.document, x_default_pos, startPos + 12);
        doc.line(x_default_pos + 16, startPos + 12, 120, startPos + 12);
        startPos += 30;
    });

    doc.save(`Orden de captura No. ${captureOrder.id}`);
}
