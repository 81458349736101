import {jsPDF} from "jspdf";
import selfJusticeNewsType from "@/enums/selfJusticeNewsType";
import autoTable from "jspdf-autotable";
import typeInvolved from "@/enums/typeInvolved";

const centeredText = (text, y, doc) => {
    const textWidth =
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
    const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, y, text);
};

const makeHeader = (lines, startPos, doc) => {
    const lineHeight = 4;
    lines.forEach((line) => {
        centeredText(line, startPos, doc);
        startPos += lineHeight;
    });
};

function getInvolved(involveds, id) {
    const involved = involveds.find((x) => x.id === id);
    if (involved)
        return `${involved.name} ${involved.lastname}`.toUpperCase();
    return "";
}

function multicell(doc, text, x, y, w) {
    const lines = doc.splitTextToSize(text, w);
    doc.text(x, y, lines);
}

export default function historyDocument(selfJusticeCase) {

    console.log(selfJusticeCase);

    const doc = new jsPDF();
    const pageHeigth = doc.internal.pageSize.height;
    const x_default_pos = 20;
    doc.setFont("Helvetica");
    doc.setFontSize(8);

    const headerLines = selfJusticeCase.headerLines

    makeHeader(headerLines, 10, doc);

    doc.setFontSize(14);

    let startY = 40;

    centeredText(`CASO: ${selfJusticeCase.code}`, startY, doc);

    startY += 10;

    doc.setFontSize(10);

    doc.text(`Fecha y hora de la desarmonia: ${selfJusticeCase.date} ${selfJusticeCase.time}`, x_default_pos, startY);
    // fecha y hora de la recepcion
    startY += 5;
    doc.text(`Fecha y hora de la recepción: ${selfJusticeCase.date2} ${selfJusticeCase.time2}`, x_default_pos, startY);
    //add territory
    const territory = selfJusticeCase.dataSource.data.Territorio.find((x) => x.territorio_id === selfJusticeCase.territory_id);
    startY += 5
    doc.text(`Territorio: ${territory?.descripcion || ''}`, x_default_pos, startY);
    startY += 5
    doc.text(`Tipo de recepcion: ${selfJusticeCase.reception_type_id}`, x_default_pos, startY);
    startY += 5
    const disarmonyType = selfJusticeCase.disharmonys_types.find((x) => x.id === selfJusticeCase.event_subtype_id);
    doc.text(`Tipo de desarmonia: ${disarmonyType?.name || ''}`, x_default_pos, startY);

    startY += 10;

    doc.setFontSize(14);
    centeredText(`Ubicacion del caso`, startY, doc);
    doc.setFontSize(10);

    startY += 10;

    // get department from datasource
    const department = selfJusticeCase.dataSource.data.Departamento.find((x) => x.departamento_id === selfJusticeCase.department_id);
    doc.text(`Departamento: ${department?.descripcion || ''}`, x_default_pos, startY);
    // get municipio from datasource
    startY += 5;
    const municipallity = selfJusticeCase.dataSource.data.Municipio.find((x) => x.municipio_id === selfJusticeCase.municipality_id);
    doc.text(`Municipio: ${municipallity?.descripcion || ''}`, x_default_pos, startY);

    startY += 5;
    const zone = selfJusticeCase.dataSource.data.Zona.find((x) => x.zona_id === selfJusticeCase.zone_id);
    doc.text(`Zona: ${zone?.descripcion || ''}`, x_default_pos, startY);

    startY += 5;
    doc.text(`Dirección exacta: ${selfJusticeCase?.address}`, x_default_pos, startY);

    startY += 5;
    doc.text(`Observaciones dirección: ${selfJusticeCase?.obs_address}`, x_default_pos, startY);

    startY += 5;
    doc.text(`Jurisdicción: ${selfJusticeCase?.jurisdiction}`, x_default_pos, startY);

    startY += 10;
    doc.text(`Otros territorios afectados`, x_default_pos, startY);

    selfJusticeCase.affected_territories.forEach((af) => {
        startY += 5;
        const territory = selfJusticeCase.dataSource.data.Territorio.find((x) => x.territorio_id === af);
        doc.text(`${territory.descripcion}`, x_default_pos, startY);
    });

    startY += 10
    doc.setFontSize(14);
    centeredText(`Descripción de los hechos`, startY, doc);
    doc.setFontSize(10);
    startY += 8
    multicell(doc, selfJusticeCase.description, x_default_pos, startY, 180);

    startY += 40;

    doc.setFontSize(14);
    centeredText(`Observaciones finales`, startY, doc);
    doc.setFontSize(10);
    startY += 8
    multicell(doc, selfJusticeCase.observation, x_default_pos, startY, 180);

    startY += 20;

    const involvedPeople = selfJusticeCase.affecteds.map((affected) => {
        return [
            selfJusticeCase.dataSource.data.Tipoidentificacion.find(
                (x) => x.tipoidentificacion_id === affected.document_type
            )?.codigo || '',
            affected.document_number,
            `${affected.name} ${affected.second_name}`,
            `${affected.lastname} ${affected.second_surname}`,
            affected.gender,
            affected.age,
            selfJusticeCase.dataSource.data.Pueblo.find((value) => value.pueblo_id === affected.indigenous_group)?.descripcion || '',
            affected.territory.descripcion,
            typeInvolved[affected.involved_type],
        ]
    });

    autoTable(doc, {
        head: [['Tipo Id', 'Documento', 'Nombres', 'Apellidos', 'Genero','Edad','Pueblo Indígena', 'Territorio', 'Tipo']],
        body: involvedPeople,
        startY: startY,
        headStyles :{
            fillColor : [67, 121, 68],
            fontSize : 10
        },
        bodyStyles: {fontSize: 8}
    })

    startY = doc.autoTable.previous.finalY + 10;

    doc.setFontSize(14);
    doc.addPage();
    startY = 20;
    centeredText(`Actualizaciones del caso`, startY, doc);
    doc.setFontSize(10);
    startY += 10;

    selfJusticeCase.news.forEach(x => {
        if ((startY + 50) < pageHeigth - 10) {
            centeredText(`${x.type.name} - Fecha : ${x.date}`, startY, doc);
            doc.setFontSize(10);
            const splitText = doc.splitTextToSize(x.description, 180);
            doc.text(x_default_pos, startY + 10, splitText);

            if(x.type.code === selfJusticeNewsType.INVESTIGATION){
                doc.text('Tipo de investigacion: ' + x.investigation.type, x_default_pos, startY + 30);
            }

            if (x.type.code === selfJusticeNewsType.CITATION) {
                doc.text('Estado: ' + x.citation.current_state.name, x_default_pos, startY + 30);
                doc.text('Lugar: ' + x.citation.place, x_default_pos, startY + 35);
                doc.text('Fecha: ' + x.citation.date, x_default_pos, startY + 40);
                doc.text('Hora: ' + x.citation.time, x_default_pos, startY + 45);
                doc.text('Citado: ' + getInvolved(selfJusticeCase.affecteds, x.citation.involved), x_default_pos, startY + 50);
            }

            if (x.type.code === selfJusticeNewsType.CAPTURE_ORDER) {
                doc.text('Orden de captura para: ' + getInvolved(selfJusticeCase.affecteds, x.capture_order.involved), x_default_pos, startY + 30);
            }

            if (x.type.code === selfJusticeNewsType.PENALTIES) {
                doc.text('Correccion para : ' + getInvolved(selfJusticeCase.affecteds, x.penalties.involved), x_default_pos, startY + 25);
                doc.text(`Fecha de inicio:  ${x.penalties.init_date} Fecha de finalizacion : ${x.penalties.end_date}`, x_default_pos, startY + 30);
                doc.text('Compromisos: ' + x.penalties.commitments, x_default_pos, startY + 35);
                doc.text(`Lugar : ${x.penalties.penalty_place}`, x_default_pos, startY + 45);
                doc.text(`Tipo de correccion ${x.penalties.penalty_type}`, x_default_pos, startY + 50);
                x.penalties.yard && doc.text(`Patio : ${x.penalties.yard}`, x_default_pos, startY + 55);
            }

            if (x.type.code === selfJusticeNewsType.PENALTY_TRACKING) {
                if (x.penalty_tracking) {
                    doc.text('Seguimiento para : ' + getInvolved(selfJusticeCase.affecteds, x.penalty_tracking.involved), x_default_pos, startY + 25);
                    //tipo de seguimiento
                    doc.text('Tipo de seguimieto : ' + x.penalty_tracking.penalty_tracking_category, x_default_pos, startY + 30);
                }

                if (x.victim_tracking) {
                    doc.text('Seguimiento para : ' + getInvolved(selfJusticeCase.affecteds, x.victim_tracking.involved), x_default_pos, startY + 25);
                    //tipo de seguimiento
                    doc.text('Tipo de seguimieto : ' + x.victim_tracking.victim_tracking_category, x_default_pos, startY + 30);
                }

            }

            if (x.type.code === selfJusticeNewsType.REVIEW_REQUEST) {
                doc.text('Nombre del solicitante : ' + x.review_request.requester_name, x_default_pos, startY + 25);
                doc.text('Documento del solicitante : ' + x.review_request.requester_document, x_default_pos, startY + 30);
            }

            startY += 55;

        } else {
            doc.addPage()
            startY = 20;
        }

    });

    // open doc in new tab
    doc.output('dataurlnewwindow', {
        filename: `Caso de justicia propia ${selfJusticeCase.id}.pdf`,
    });

}
