<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">
          <v-icon class="mr-2" small>mdi-message-reply-text-outline</v-icon> Ver
          Respuesta
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <span class="green--text">Tipo de respuesta</span>
              <v-radio-group readonly v-model="response.is_approve" row>
                <v-radio label="Aprobado" :value="true"></v-radio>
                <v-radio label="Desaprobado" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                readonly
                v-model="response.date"
                solo
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <span class="green--text">Concepto</span>
              <v-textarea
                readonly
                v-model="response.concept"
                row
                solo
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    response: {
      date: "",
      is_approve: false,
      concept: "",
    },
  }),
  methods: {
    async open(response) {
      this.response = response;
      this.dialog = true;
    },
  },
};
</script>
