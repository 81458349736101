var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"overflow-hidden"},[_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',{attrs:{"href":"#victim"}},[_vm._v(" Desarmonizados ")]),_c('v-tab-item',{attrs:{"value":"victim"}},[_c('br'),_c('v-data-table',{staticClass:"mt-2",attrs:{"footer-props":{
            'items-per-page-text': 'Filas por página',
                  'items-per-page-options': [5,10,15]
            
          },"headers":_vm.victimHeaders,"items":_vm.involvedFilteredVictim,"item-key":"id","show-select":"","show-expand":"","single-select":true},scopedSlots:_vm._u([{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":"12"}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"mt-5"},[_c('strong',{staticClass:"ml-6",staticStyle:{"color":"#43794e"}},[_vm._v("Ruta de segumiento")]),_c('v-breadcrumbs',{attrs:{"items":_vm.mapVictimTracking(item.victim_tracking)},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticStyle:{"color":"#43794e","cursor":"pointer"},on:{"click":function($event){return _vm.getNewsVictim(item)}}},[_vm._v(" "+_vm._s(item.text))])]}},{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}],null,true)})],1)])],1)],1)]}},{key:"item.data-table-select",fn:function({ item, isSelected, select }){return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":isSelected,"indeterminate-icon":"mdi-account-alert"},on:{"input":function($event){select($event);
                _vm.handlePenalty(item, $event);}}})]}},{key:"item.penalty",fn:function({ item }){return [_c('v-chip',{attrs:{"color":"warning"}},[_vm._v(_vm._s(item.penalties[0]?.penalty_type?.name))])]}},{key:"item.involved_type",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.typesInvolved[item.involved_type])+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"green--text"},[_vm._v("Tipo de seguimiento")]),_c('v-autocomplete',{attrs:{"items":this.categoriesVictim,"dense":"","item-text":"name","item-value":"id","placeholder":"Seleccione un valor","solo":"","error-messages":_vm.validationErrors.penaltyTrackingType},model:{value:(_vm.penaltyTrackingType),callback:function ($$v) {_vm.penaltyTrackingType=$$v},expression:"penaltyTrackingType"}})],1)],1)],1),_c('v-tab',{attrs:{"href":"#victimize"}},[_vm._v(" Desarmonizantes ")]),_c('v-tab-item',{attrs:{"value":"victimize"}},[_c('br'),_c('v-data-table',{staticClass:"mt-2",attrs:{"footer-props":{
            'items-per-page-text': 'Filas por página',
                  'items-per-page-options': [5,10,15]

          },"headers":_vm.headers,"items":_vm.involvedFilteredVictimize,"item-key":"id","show-select":"","show-expand":"","single-select":true},scopedSlots:_vm._u([{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":"12"}},[_c('v-row',[_c('v-col',_vm._l((item.penalties),function(penalty){return _c('div',{key:`${penalty.id}_2penalty`,staticClass:"mt-5"},[_c('p',[_c('strong',[_vm._v("Corrección :")]),_vm._v(" "+_vm._s(penalty.penalty_type.name)+" ")]),_c('p',[_c('strong',[_vm._v("Lugar :")]),_vm._v(" "+_vm._s(penalty.penalty_place.name)+" ")]),_c('p',[_c('strong',[_vm._v("Inica desde:")]),_vm._v(" "+_vm._s(penalty.init_date)+" ")]),_c('p',[_c('strong',[_vm._v("Finaliza hasta")]),_vm._v(": "+_vm._s(penalty.end_date)+" ")])])}),0),_c('v-col',[_c('div',{staticClass:"mt-5"},[_c('strong',{staticClass:"ml-6",staticStyle:{"color":"#43794e"}},[_vm._v("Ruta de segumiento")]),_c('v-breadcrumbs',{attrs:{"items":_vm.mapTracking(item.penalty_tracking)},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticStyle:{"color":"#43794e","cursor":"pointer"},on:{"click":function($event){return _vm.getNews(item)}}},[_vm._v(" "+_vm._s(item.text))])]}},{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}],null,true)})],1)])],1)],1)]}},{key:"item.data-table-select",fn:function({ item, isSelected, select }){return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":isSelected,"indeterminate-icon":"mdi-account-alert"},on:{"input":function($event){select($event);
                _vm.handlePenalty(item, $event);}}})]}},{key:"item.penalty",fn:function({ item }){return [_c('v-chip',{attrs:{"color":"warning"}},[_vm._v(_vm._s(item.penalties[0]?.penalty_type?.name))])]}},{key:"item.involved_type",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.typesInvolved[item.involved_type])+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"green--text"},[_vm._v("Tipo de seguimiento")]),_c('v-autocomplete',{attrs:{"items":this.categoriesVictimize,"dense":"","item-text":"name","item-value":"id","placeholder":"Seleccione un valor","solo":"","error-messages":_vm.validationErrors.penaltyTrackingType},model:{value:(_vm.penaltyTrackingType),callback:function ($$v) {_vm.penaltyTrackingType=$$v},expression:"penaltyTrackingType"}})],1)],1)],1)],1)],1),_c('NewsInfoModal',_vm._b({ref:"newsInfo"},'NewsInfoModal',_vm.modalProps,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }