<template>
  <v-card dark color="primary" class="elevation-5 border-primary">
    <v-card-title class="text-h6">Correccion</v-card-title>
    <v-card-text class="white text--primary pt-1">
      <v-chip label small color="green" text-color="white">
        <v-avatar left>
          <v-icon>mdi-account-circle</v-icon>
        </v-avatar>
        {{ getInvolved(news.penalties.involved) }}
      </v-chip>
      <p>{{ news.description }}</p>
      <p>Fecha de inicio : {{news.penalties.init_date}} Fecha Final : {{news.penalties.end_date}}</p>
      <p>Compromisos : {{news.penalties.commitments}}</p>
      <p>Tipo de correccion : {{news.penalties.penalty_type}}</p>
      <p>Lugar de la correccion : {{news.penalties.penalty_place}}</p>
      <p v-if="news.penalties.yard">Patio: {{news.penalties.yard}}</p>
      <v-row>
        <v-col cols="12">
          <evidence-item v-for="evidence in news.evidences" :key="evidence.id" :evidence-item="evidence"></evidence-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import evidenceItem from "@/components/commonComponents/EvidenceItem";
export default {
  components : {evidenceItem},
  props: ["news", "involved"],
  data() {
    return {};
  },
  methods: {
    getInvolved(id) {
      const involved = this.involved.find((x) => x.id === id);
      if (involved)
        return `${involved.name} ${involved.lastname}`.toUpperCase();
      return "";
    }
  },
};
</script>

<style scoped></style>
