import eventTypes from "../enums/selfJusticeNewsType";

function getEventTypesIcons(state) {
  return {
    [eventTypes.CITATION]: "mdi-calendar",
    [eventTypes.INVESTIGATION]: "mdi-magnify-expand",
    [eventTypes.CAPTURE_ORDER]: "mdi-file-lock",
    [eventTypes.PENALTIES]: "mdi-account-lock",
    [eventTypes.PENALTY_TRACKING]: "mdi-account-star",
    [eventTypes.REVIEW_REQUEST]: "mdi-email-arrow-right",
    [eventTypes.CLOSE]: "mdi-close-octagon",
    [eventTypes.ALERT]: "mdi-bell",
  }[state];
}

export default getEventTypesIcons;
