<template>
  <v-card>
    <v-card-title>
      {{ getTitleComponent(type) }}
      <v-spacer></v-spacer>
      <v-btn color="primary" x-small rounded @click="historyDialog=true">Ver histórico</v-btn>
    </v-card-title>
    <component
      @updated="$emit('updated')"
      :is="getComponent(type)"
      :ref="getComponent(type)"
      v-bind="getComponentProps(type)"
      @onEdit="setUpdateData($event)"
      @onReset="resetUpdateData($event)"
    >
    </component>
    <v-card-text>
      <span class="green--text"> {{ getDescFieldComponent(type) }}</span>
      <v-textarea
        solo
        dense
        v-model="description"
        :error-messages="validationErrors.description"
      ></v-textarea>
      <div v-if="hasDate">
        <span class="green--text">Fecha</span>
        <v-text-field
          type="date"
          v-model="date"
          solo
          dense
          :error-messages="validationErrors.date"
        ></v-text-field>
      </div>
      <evidences
        module="self_justice"
        :object-id="newsId"
        content-type="News"
        ref="evidenceComponent"
      ></evidences>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn color="primary" rounded @click="save()" v-if="$can('handle_self_justice')">{{ buttonText || "Guardar" }}</v-btn>
        </v-col>
      </v-row>
      <change-state
        :state-code="changeStatusCode"
        :case-id="caseId"
        ref="changeStatusComponent"
        @changeState="$emit('updated')"
      ></change-state>
    </v-card-text>
    <v-dialog
        v-model="historyDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="historyDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-icon left>mdi-clipboard-text-clock</v-icon>
            HISTORICO
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="8" offset="2">
              <news-list
                  :newsList="news"
                  :involveds="involveds"
                  :news-type-filter="type"
              ></news-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import Affected from "@/views/SelfJustice/components/Affecteds";
import Citation from "@/views/SelfJustice/components/Citation";
import CaptureOrder from "@/views/SelfJustice/components/CaptureOrder";
import Penalties from "@/views/SelfJustice/components/Penalties";
import PenaltyTracking from "@/views/SelfJustice/components/PenaltyTracking";
import selfJusticeNewsType from "@/enums/selfJusticeNewsType";
import ReviewRequest from "@/views/SelfJustice/components/ReviewRequest";
import Alerts from "@/views/SelfJustice/components/Alerts";
import Close from "@/views/SelfJustice/components/Close";
import { required } from "vuelidate/lib/validators";
import { storeNews } from "@/services/SelfJustice";
import Swal from "sweetalert2";
import { mapState } from "vuex";
import Evidences from "@/components/commonComponents/Evidences";
import ChangeState from "./ChangeState";
import selfJusticeStates from "@/enums/selfJusticeStates";
import NewsList from "@/views/SelfJustice/components/NewsList/NewsList";
export default {
  props: ["type", "type_id", "caseId", "involveds", "news", "territory"],
  components: {
    NewsList,
    ChangeState,
    Evidences,
    Affected,
    Citation,
    CaptureOrder,
    Penalties,
    PenaltyTracking,
    ReviewRequest,
    Alerts,
    Close,
  },
  data() {
    return {
      description: "",
      date: "",
      newsId: "",
      buttonText: "",
      isSent: null,
      changeStatusCode: "",
      historyDialog: false,
    };
  },
  validations() {
    return {
      description: { required },
      date: { required },
    };
  },
  methods: {
    getComponent(type) {
      return this.newsTypesComponent[type];
    },
    getComponentProps(type) {
      return this.newsTypesComponentProps[type];
    },
    getTitleComponent(type) {
      return {
        [selfJusticeNewsType.INVESTIGATION]: "Registro de novedades del caso",
        [selfJusticeNewsType.CITATION]: "Registrar una citación",
        [selfJusticeNewsType.CAPTURE_ORDER]: "Radicar una order de captura",
        [selfJusticeNewsType.PENALTIES]: "Aplicar una correción",
        [selfJusticeNewsType.PENALTY_TRACKING]: "Seguimiento de la corrección",
        [selfJusticeNewsType.REVIEW_REQUEST]: "Solicitud de revisión",
        [selfJusticeNewsType.ALERT]: "Alertas de fuga",
        [selfJusticeNewsType.CLOSE]: "Cerrar Caso",
      }[type];
    },
    getDescFieldComponent(type) {
      return {
        [selfJusticeNewsType.INVESTIGATION]: "Descripción",
        [selfJusticeNewsType.CITATION]: "Observaciones",
        [selfJusticeNewsType.CAPTURE_ORDER]: "Observaciones",
        [selfJusticeNewsType.PENALTIES]: "Observaciones",
        [selfJusticeNewsType.PENALTY_TRACKING]: "Descripción",
        [selfJusticeNewsType.REVIEW_REQUEST]: "Solicitud",
        [selfJusticeNewsType.ALERT]: "Solicitud",
      }[type];
    },
    isNewsChangeState(type) {
      return {
        [selfJusticeNewsType.INVESTIGATION]: true,
        [selfJusticeNewsType.CITATION]: false,
        [selfJusticeNewsType.CAPTURE_ORDER]: false,
        [selfJusticeNewsType.PENALTIES]: true,
        [selfJusticeNewsType.PENALTY_TRACKING]: true,
        [selfJusticeNewsType.REVIEW_REQUEST]: false,
        [selfJusticeNewsType.ALERT]: false,
        [selfJusticeNewsType.CLOSE]: true,
      }[type];
    },
    getStatusCode(type) {
      return {
        [selfJusticeNewsType.PENALTIES]: this.selfJusticeStates.CORRECTION,
        [selfJusticeNewsType.PENALTY_TRACKING]: this.selfJusticeStates.TRACKING,
        [selfJusticeNewsType.INVESTIGATION]: this.selfJusticeStates.INVESTIGATION,
        [selfJusticeNewsType.CLOSE]: this.selfJusticeStates.CLOSE,
      }[type];
    },
    async save() {
      const componentData = this.$refs[this.getComponent(this.type)].getData();
      this.date = this.hasDate
        ? this.date
        : new Date().toISOString().split("T")[0];
      this.$v.$touch();
      if (this.$v.$invalid || !componentData) return;
      try {
        this.showLoader();
        const response = await storeNews({
          case_id: this.caseId,
          type_id: this.type_id,
          date: this.date,
          description: this.description,
          user_id: this.user.id,
          [this.type.toLowerCase()]: componentData,
        });
        this.newsId = response.data.news_id;
        await this.$nextTick();
        await this.$refs.evidenceComponent.upload();
        this.hideLoader();
        this.$emit("updated");
        this.resetUpdateData();
        this.isSent = true;

        await Swal.fire("Registro guardado correctamente", "", "success");

        setTimeout(() => (this.isSent = false), 500);

        this.$refs.evidenceComponent.clean();
        this.description = "";
        this.date = "";
        this.$refs[this.getComponent(this.type)].clearForm();

        if (this.isNewsChangeState(this.type)) {
          this.changeStatusCode = this.getStatusCode(this.type);
          await this.$nextTick();
          this.$refs.changeStatusComponent.open();
        }

      } catch (e) {
        this.hideLoader();
        console.error(e);
        Swal.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    setUpdateData(item) {
      this.buttonText = "Modificar";
      this.description = item.description || this.description;
    },
    resetUpdateData(item) {
      this.buttonText = null;
      this.description = "";
      console.log(item);
    },
  },
  computed: {
    hasDate() {
      return (
        this.type !== selfJusticeNewsType.PENALTIES &&
        this.type !== selfJusticeNewsType.CITATION
      );
    },
    newsTypesComponent() {
      return {
        [selfJusticeNewsType.INVESTIGATION]: "Affected",
        [selfJusticeNewsType.CITATION]: "Citation",
        [selfJusticeNewsType.CAPTURE_ORDER]: "CaptureOrder",
        [selfJusticeNewsType.PENALTIES]: "Penalties",
        [selfJusticeNewsType.PENALTY_TRACKING]: "PenaltyTracking",
        [selfJusticeNewsType.REVIEW_REQUEST]: "ReviewRequest",
        [selfJusticeNewsType.ALERT]: "Alerts",
        [selfJusticeNewsType.CLOSE]: "Close",
      };
    },
    newsTypesComponentProps() {
      return {
        [selfJusticeNewsType.INVESTIGATION]: {
          newsList: this.news.filter(
            (x) => x.type.code === selfJusticeNewsType.INVESTIGATION
          ),
        },
        [selfJusticeNewsType.CITATION]: {
          involved: this.involveds,
          citations: this.news.filter(
            (x) => x.type.code === selfJusticeNewsType.CITATION
          ),
          news : this.news,
          territory : this.territory
        },
        [selfJusticeNewsType.PENALTIES]: {
          involved: this.involveds,
          news : this.news,
        },
        [selfJusticeNewsType.CAPTURE_ORDER]: {
          involved: this.involveds,
          isSent: this.isSent,
          capture_orders: this.news.filter(
            (x) => x.type.code === selfJusticeNewsType.CAPTURE_ORDER
          ),
          territory : this.territory
        },
        [selfJusticeNewsType.PENALTY_TRACKING]: {
          involved: this.involveds,
          news : this.news
        },
        [selfJusticeNewsType.REVIEW_REQUEST]: {
          requests: this.news.filter(
            (x) => x.type.code === selfJusticeNewsType.REVIEW_REQUEST
          ),
        },
        [selfJusticeNewsType.ALERT]: {
          involved: this.involveds,
          news: this.news,
        },
        [selfJusticeNewsType.CLOSE]: {},
      };
    },
    validationErrors() {
      const validationObject = {
        description: [
          ...[
            !this.$v.description.required && this.$v.description.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
        date: [
          ...[
            !this.$v.date.required && this.$v.description.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
      };

      for (const key in validationObject)
        validationObject[key] = validationObject[key].filter((x) => x !== "");

      return validationObject;
      // return
    },
    selfJusticeStates() {

      return selfJusticeStates;
    },
    ...mapState(["user"]),
  },
};
</script>
