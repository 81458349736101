<template>
  <main>
    <v-timeline>
      <v-timeline-item v-for="(news, i) in filteredNewsList" :key="`${i}`" small>
        <template v-slot:opposite>
        <span
            class="font-weight-bold light-green--text"
            v-text="news.date"
        ></span>
        </template>
        <component
            v-if="getNewsTypesComponent(news.type.code)"
            :is="getNewsTypesComponent(news.type.code)"
            :news="news"
            v-bind="getComponentProps(news.type.code)"
        ></component>
      </v-timeline-item>
    </v-timeline>
    <v-alert border="top"
             colored-border
             type="primary"
             elevation="2"
             v-if="!filteredNewsList.length">
      No hay datos para mostrar
    </v-alert>
  </main>
</template>

<script>
import selfJusticeNewsType from "@/enums/selfJusticeNewsType";
import AffectedShow from "@/views/SelfJustice/components/NewsList/AffectedsShow";
import CaptureOrderShow from "@/views/SelfJustice/components/NewsList/CaptureOrderShow";
import CitationShow from "@/views/SelfJustice/components/NewsList/CitationShow";
import CloseShow from "@/views/SelfJustice/components/NewsList/CloseShow";
import AlertShow from "@/views/SelfJustice/components/NewsList/AlertShow";
import PenaltiesShow from "@/views/SelfJustice/components/NewsList/PenaltiesShow";
import PenaltyTrackingShow from "@/views/SelfJustice/components/NewsList/PenaltyTrackingShow";
import ReviewRequestShow from "@/views/SelfJustice/components/NewsList/ReviewRequestShow";
export default {
  name: "NewsList",
  props: ["newsList", "involveds", "newsTypeFilter"],
  components: {
    AffectedShow,
    CaptureOrderShow,
    CitationShow,
    CloseShow,
    PenaltiesShow,
    PenaltyTrackingShow,
    ReviewRequestShow,
    AlertShow,
  },
  methods: {
    getNewsTypesComponent(type) {
      return {
        [selfJusticeNewsType.INVESTIGATION]: "AffectedShow",
        [selfJusticeNewsType.CITATION]: "CitationShow",
        [selfJusticeNewsType.CAPTURE_ORDER]: "CaptureOrderShow",
        [selfJusticeNewsType.PENALTIES]: "PenaltiesShow",
        [selfJusticeNewsType.PENALTY_TRACKING]: "PenaltyTrackingShow",
        [selfJusticeNewsType.REVIEW_REQUEST]: "ReviewRequestShow",
        [selfJusticeNewsType.CLOSE]: "CloseShow",
        [selfJusticeNewsType.ALERT]: "AlertShow",
      }[type];
    },
    getComponentProps(type) {
      return {
        [selfJusticeNewsType.INVESTIGATION]: {
          involved: this.involveds,
        },
        [selfJusticeNewsType.CITATION]: {
          involved: this.involveds,
        },
        [selfJusticeNewsType.PENALTIES]: {
          involved: this.involveds,
        },
        [selfJusticeNewsType.CAPTURE_ORDER]: {
          involved: this.involveds,
        },
        [selfJusticeNewsType.PENALTY_TRACKING]: {
          involved: this.involveds,
        },
        [selfJusticeNewsType.REVIEW_REQUEST]: {
          involved: this.involveds,
        },
        [selfJusticeNewsType.ALERT]: {
          involved: this.involveds,
        },
        [selfJusticeNewsType.CLOSE]: {},
      }[type];
    },
  },
  computed: {
    filteredNewsList() {
      if(this.newsTypeFilter === undefined){
        return this.newsList;
      }
      return this.newsList.filter((news) => news.type.code === this.newsTypeFilter);
    },
  },
};
</script>

<style scoped></style>
