<template>
  <v-container fluid>
    <change-state
        :state-code="selfJusticeStates.INVESTIGATION"
        :case-id="Number(id_disharmony)"
        ref="changeStatusComponent"
        @changeState="showCase()"
    ></change-state>
    <v-dialog
        v-model="historyDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="historyDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-icon left>mdi-clipboard-text-clock</v-icon>
            HISTORICO
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click="historyDocument()"
            >
              Exportar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="8" offset="2">
              <news-list
                  :newsList="newDisharmony.news"
                  :involveds="newDisharmony.affecteds"
              ></news-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
            <v-icon>mdi-chevron-double-right</v-icon>
            <v-icon>mdi-chevron-double-right</v-icon>
            LISTA DE CASOS
            <v-icon>mdi-chevron-double-right</v-icon>
            Detalle del caso
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>
    <template>
      <v-card>
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Información del caso</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="mr-5" rounded color="info" @click="historyDialog=true"> <v-icon left>mdi-clipboard-text-clock</v-icon> HISTORIAL</v-btn>
          <v-btn v-if="newDisharmony.current_state.code === 'CLOSE'" class="mr-5" rounded color="warning" @click="$refs.changeStatusComponent.open()">
            <v-icon left>
              mdi-lock-open-variant
            </v-icon>
            ABRIR CASO
          </v-btn>
          <v-chip small outlined text-colorÏ="white" color="white" class="ml-2">{{((newDisharmony.current_state ? newDisharmony.current_state.name : 'INICIAL'))}}</v-chip>
        </v-toolbar>
        <v-tabs vertical>
          <v-tab style="justify-content: flex-start">
            <v-icon left>mdi-information</v-icon>
            Información del caso
          </v-tab>
          <v-tab
            v-for="newType in newsTypes"
            :key="newType.id"
            style="justify-content: flex-start"
            :disabled="newDisharmony.current_state.code==='CLOSE'"
          >
            <v-icon left>{{ getIcon(newType.code) || "" }}</v-icon>{{ newType.name }}
          </v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-card class="overflow-hidden">
                  <v-toolbar color="white" flat>
                    <v-toolbar-title class="grey--text font-weight-bold">
                      TERRITORIO DE COMPETENCIA
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row class="mt-2">
                      <v-col cols="12" lg="4" md="6">
                        <span class="green--text">Territorio</span>
                        <v-autocomplete
                            v-model="newDisharmony.territory_id"
                            :error-messages="validationErrors.territory_id"
                            :items="territories"
                            dense
                            item-text="descripcion"
                            item-value="territorio_id"
                            placeholder="Seleccione un valor"
                            solo
                            readonly="readonly"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <!-- CARD DESCRIPCION DEL EVENTO -->
                <v-card class="overflow-hidden">
                  <v-toolbar color="white" flat>
                    <v-toolbar-title class="grey--text font-weight-bold">
                      FECHA Y TIPO DE DESARMONÍA
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <span class="black--text font-weight-bold"
                      >Estado actual:
                      {{ newDisharmony.current_state ? newDisharmony.current_state.name : "INICIAL"}}</span
                    >
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" lg="3" md="6" sm="12">
                        <span class="green--text">Fecha de la recepción</span>
                        <v-text-field
                            v-model="newDisharmony.date"
                            :error-messages="validationErrors.date"
                            dense
                            solo
                            readonly="readonly"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="3" md="6" sm="12">
                        <span class="green--text">Hora de la recepción</span>
                        <v-text-field
                            v-model="newDisharmony.time"
                            :error-messages="validationErrors.time"
                            dense
                            readonly
                            solo
                            type="time"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="3" md="6" sm="12">
                        <span class="green--text">Fecha de la desarmonía</span>
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          min-width="auto"
                          offset-y
                          transition="scale-transition"
                          @input="concatDateDishar"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="newDisharmony.date2"
                              :error-messages="validationErrors.date2"
                              dense
                              solo
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            locale="es-co"
                            :max="maxDate"
                            v-model="newDisharmony.date2"
                            solo
                            @input="menu2 = false"

                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" lg="3" md="6" sm="12">
                        <span class="green--text">Hora de la desarmonía</span>
                        <v-text-field
                            v-model="newDisharmony.time2"
                            :error-messages="validationErrors.time2"
                            dense
                            solo
                            type="time"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6" md="6" sm="12">
                        <span class="green--text">Tipo de recepción</span>
                        <v-autocomplete
                          v-model="newDisharmony.reception_type_id"
                          :error-messages="validationErrors.reception_type_id"
                          :items="references_types.reception_types"
                          dense
                          item-text="link"
                          item-value="link"
                          placeholder="Seleccione un valor"
                          solo
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" lg="6" md="6" sm="12">
                        <span class="green--text">Tipo de desarmonía</span>
                        <v-autocomplete
                          v-model="newDisharmony.event_subtype_id"
                          :error-messages="validationErrors.event_subtype_id"
                          :items="disharmonys_types"
                          dense
                          item-text="name"
                          item-value="id"
                          placeholder="Seleccione un valor"
                          solo
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        v-for="(field, i) in requiredData"
                        :key="i"
                        cols="12"
                        lg="4"
                        md="6"
                        sm="12"
                      >
                        <span class="green--text">{{ field.name }}</span>
                        <v-autocomplete
                          dense
                          placeholder="Seleccione un valor"
                          solo
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <!-- CARD UBICACION DEL EVENTO -->
                <v-card class="overflow-hidden">
                  <v-toolbar color="white" flat>
                    <v-toolbar-title class="grey--text font-weight-bold">
                      UBICACIÓN DESARMONÍA
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row class="mt-2">
                      <v-col cols="12" lg="4" md="6">
                        <span class="green--text">Departamento</span>
                        <v-autocomplete
                          v-model="newDisharmony.department_id"
                          :error-messages="validationErrors.department_id"
                          @input="changeDepto = true"
                          :items="departments"
                          dense
                          item-text="descripcion"
                          item-value="departamento_id"
                          placeholder="Seleccione un valor"
                          solo
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" lg="4" md="6">
                        <span class="green--text">Zona</span>
                        <v-autocomplete
                          v-model="newDisharmony.zone_id"
                          :error-messages="validationErrors.zone_id"
                          :items="zoneFilteredByDepartment"
                          dense
                          item-text="descripcion"
                          item-value="zona_id"
                          placeholder="Seleccione un valor"
                          solo
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" lg="4" md="6">
                        <span class="green--text">Municipio</span>
                        <v-autocomplete
                          v-model="newDisharmony.municipality_id"
                          :error-messages="validationErrors.municipality_id"
                          :items="municipalitiesFilter"
                          dense
                          item-text="descripcion"
                          item-value="municipio_id"
                          placeholder="Seleccione un valor"
                          solo
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" lg="4" md="6">
                        <span class="green--text">Vereda</span>
                        <v-autocomplete
                          v-model="newDisharmony.sidewalk_id"
                          :items="villagesFilter"
                          dense
                          item-text="name"
                          item-value="id"
                          placeholder="Seleccione un valor"
                          solo
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" lg="4" md="6">
                        <span class="green--text">Dirección exacta</span>
                        <v-text-field
                          v-model="newDisharmony.address"
                          :error-messages="validationErrors.address"
                          dense
                          placeholder="Escriba su nombre"
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4" md="6">
                        <span class="green--text">Observaciones dirección</span>
                        <v-text-field
                          v-model="newDisharmony.obs_address"
                          :error-messages="validationErrors.obs_address"
                          dense
                          placeholder="Escriba su dirección"
                          solo
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="4" md="6">
                        <span class="green--text"
                          >Afecta otros territorio(s) seleccione(los)
                        </span>
                        <v-select
                          v-model="other_territories"
                          :items="territories"
                          :menu-props="{ maxHeight: '400' }"
                          hint="seleccionar territorio(s)"
                          item-text="descripcion"
                          item-value="territorio_id"
                          label="Select"
                          multiple
                          persistent-hint
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="4" md="6">
                        <span class="green--text">Jurisdicción</span>
                        <v-autocomplete
                          v-model="newDisharmony.jurisdiction"
                          :error-messages="validationErrors.jurisdiction"
                          :items="references_types.jurisdictions"
                          dense
                          item-text="nombre"
                          item-value="name"
                          placeholder="Seleccione un valor"
                          solo
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                          v-if="newDisharmony.jurisdiction == 'LOCAL'"
                          cols="12"
                          lg="4"
                          md="6"
                      >
                        <v-checkbox
                            v-model="newDisharmony.is_confidential"
                            label="Es confidencial?"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <!-- CARD INFORMANTE DEL HECHO -->
                <v-card class="overflow-hidden">
                  <v-toolbar color="white" flat>
                    <v-toolbar-title class="grey--text font-weight-bold">
                      INFORMANTE DEL HECHO
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row class="mt-2">
                      <v-col cols="12" lg="5" md="6">
                        <span class="green--text">Tipo de Documento</span>
                        <v-autocomplete
                          v-model="newDisharmony.document_type"
                          :error-messages="validationErrors.document_type"
                          :items="tp_identifications"
                          dense
                          item-text="descripcion"
                          item-value="tipoidentificacion_id"
                          placeholder="Seleccione un valor"
                          solo
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" lg="5" md="6">
                        <span class="green--text"
                          >Número de documento de identificación</span
                        >
                        <v-text-field
                          v-model="
                            newDisharmony.contact_information.identification
                          "
                          :error-messages="
                            validationErrors.contact_information.identification
                          "
                          dense
                          @keydown.enter.prevent="searchComuner()"
                          hint="Presione enter para buscar en censo"
                          placeholder="Escriba su no. documento"
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="3" md="6">
                        <span class="green--text">Primer nombre</span>
                        <v-text-field
                          v-model="newDisharmony.contact_information.name"
                          :error-messages="
                            validationErrors.contact_information.name
                          "
                          dense
                          placeholder="Escriba su primer nombre"
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="3" md="6">
                        <span class="green--text"
                          >Segundo nombre (Opcional)</span
                        >
                        <v-text-field
                          v-model="newDisharmony.contact_information.name2"
                          :error-messages="
                            validationErrors.contact_information.name2
                          "
                          dense
                          placeholder="Escriba su segundo nombre"
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="3" md="6">
                        <span class="green--text">Primer apellido</span>
                        <v-text-field
                          v-model="newDisharmony.contact_information.ape1"
                          :error-messages="
                            validationErrors.contact_information.ape1
                          "
                          dense
                          placeholder="Escriba su primer apellido"
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="3" md="6">
                        <span class="green--text"
                          >Segundo apellido (Opcional)</span
                        >
                        <v-text-field
                          v-model="newDisharmony.contact_information.ape2"
                          :error-messages="
                            validationErrors.contact_information.ape2
                          "
                          dense
                          placeholder="Escriba su segundo apellido"
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="5" md="6">
                        <span class="green--text">Número de contacto</span>
                        <v-text-field
                          v-model="
                            newDisharmony.contact_information.phone_number
                          "
                          :error-messages="
                            validationErrors.contact_information.phone_number
                          "
                          dense
                          placeholder="Escriba su no. de contacto"
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="5" md="6">
                        <span class="green--text">Correo electrónico</span>
                        <v-text-field
                          v-model="newDisharmony.contact_information.email"
                          :error-messages="
                            validationErrors.contact_information.email
                          "
                          dense
                          placeholder="Escriba su correo electrónico"
                          solo
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <!-- CARD DESCRIPCIÓN DE LOS HECHOS -->
                <v-card class="overflow-hidden">
                  <v-toolbar color="white" flat>
                    <v-toolbar-title class="grey--text font-weight-bold">
                      DESCRIPCIÓN DE LOS HECHOS
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row class="mt-2">
                      <v-col cols="12" lg="12" md="6">
                        <v-textarea
                          v-model="description"
                          :error-messages="validationErrors.description"
                          hint=""
                          label="Escriba el texto aquí..."
                          name="input-7-4"
                          solo
                          dense
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <!-- CARD INFORMACION AFECTADOS -->
                <v-card class="overflow-hidden">
                  <v-toolbar color="white" flat>
                    <v-toolbar-title class="grey--text font-weight-bold">
                      DESARMONIZADO(S)
                    </v-toolbar-title>
                    <affected-people
                      :involved_type="'VICTIM'"
                      @affected="addAffected"
                      :affecteds="newDisharmony.affecteds"
                    ></affected-people>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-data-table
                      :footer-props="{
                        'items-per-page-text': 'Filas por página',

                        'items-per-page-options': [5,10,15]

                      }"
                      :headers="headers"
                      :items="filteredVictim"
                      class="mt-2"
                      item-key="id"
                    >
                      <template v-slot:item.indigenous_group="{ item }">
                        {{dataSource.data.Pueblo.find((value) => value.pueblo_id == item.indigenous_group)?.descripcion}}
                      </template>
                      <template v-slot:item.name="{ item }">
                        <span
                          v-text="`${item.name} ${item.second_name}`"
                        ></span>
                      </template>
                      <template v-slot:item.lastname="{ item }">
                        <span
                          v-text="`${item.lastname} ${item.second_surname}`"
                        ></span>
                      </template>
                      <template v-slot:item.action="{ item }">
                        <v-menu bottom left>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <affected-people-edit
                              :involved_type="'VICTIM'"
                              @affected="editImplicated"
                              :affecteds="newDisharmony.affecteds"
                              :affectedEdit="{...item}"
                              :documentInitial="item?.document_number.toString()"
                            ></affected-people-edit>
                            <v-list-item @click="deleteItem(item?.document_number)" v-if="item.newInvolved">
                              <v-list-item-title>
                                <v-icon class="mr-2" small
                                >mdi-circle-edit-outline
                                </v-icon
                                >
                                Eliminar
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
                <!-- CARD INFORMACION IMPLICADOS -->
                <v-card class="overflow-hidden">
                  <v-toolbar color="white" flat>
                    <v-toolbar-title class="grey--text font-weight-bold">
                      DESARMONIZANTE(S)
                    </v-toolbar-title>
                    <affected-people
                      :involved_type="'VICTIMIZE'"
                      :affecteds="newDisharmony.affecteds"
                      @affected="addAffected"
                    ></affected-people>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-data-table
                      :footer-props="{
                        'items-per-page-text': 'Filas por página',
                        'items-per-page-options': [5,10,15]

                      }"
                      :headers="headers"
                      :items="filteredVictimize"
                      class="mt-2"
                      item-key="id"
                    >
                      <template v-slot:item.state="{ item }">
                        <v-chip color="red" outlined>
                          {{ item.state }}
                        </v-chip>
                      </template>
                      <template v-slot:item.name="{ item }">
                        <span
                            v-text="`${item.name} ${item.second_name}`"
                        ></span>
                      </template>
                      <template v-slot:item.lastname="{ item }">
                        <span
                            v-text="`${item.lastname} ${item.second_surname}`"
                        ></span>
                      </template>
                      <template v-slot:item.indigenous_group="{ item }">
                        {{dataSource.data.Pueblo.find((value) => value.pueblo_id == item.indigenous_group)?.descripcion}}
                      </template>
                      <template v-slot:item.action="{ item }">
                        <v-menu bottom left>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <affected-people-edit
                              :involved_type="'VICTIMIZE'"
                              @affected="editImplicated"
                              :affecteds="newDisharmony.affecteds"
                              :affectedEdit="{...item}"
                              :documentInitial="item?.document_number.toString()"
                            ></affected-people-edit>
                            <v-list-item @click="deleteItem(item?.document_number)" v-if="item.newInvolved">
                              <v-list-item-title>
                                <v-icon class="mr-2" small
                                >mdi-circle-edit-outline
                                </v-icon
                                >
                                Eliminar
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
                <!-- CARD INFORMACION TESTIGOS -->
                <v-card class="overflow-hidden">
                  <v-toolbar color="white" flat>
                    <v-toolbar-title class="grey--text font-weight-bold">
                      TESTIGO(S)
                    </v-toolbar-title>
                    <affected-people
                      :involved_type="'WITNESS'"
                      @affected="addAffected"
                      :affecteds="newDisharmony.affecteds"
                    ></affected-people>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-data-table
                      :footer-props="{
                        'items-per-page-text': 'Filas por página',
                        'items-per-page-options': [5,10,15]

                      }"
                      :headers="headers"
                      :items="filteredWitness"
                      class="mt-2"
                      item-key="id"
                    >
                      <template v-slot:item.indigenous_group="{ item }">
                        {{dataSource.data.Pueblo.find((value) => value.pueblo_id == item.indigenous_group)?.descripcion}}
                      </template>
                      <template v-slot:item.action="{ item }">
                        <v-menu bottom left>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <affected-people-edit
                              :involved_type="'WITNESS'"
                              @affected="editImplicated"
                              :affecteds="newDisharmony.affecteds"
                              :affectedEdit="{...item}"
                              :documentInitial="item?.document_number.toString()"
                            ></affected-people-edit>
                            <v-list-item @click="deleteItem(item?.document_number)" v-if="item.newInvolved">
                              <v-list-item-title>
                                <v-icon class="mr-2" small
                                >mdi-circle-edit-outline
                                </v-icon
                                >
                                Eliminar
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                      <template v-slot:item.name="{ item }">
                        <span
                            v-text="`${item.name} ${item.second_name}`"
                        ></span>
                      </template>
                      <template v-slot:item.lastname="{ item }">
                        <span
                            v-text="`${item.lastname} ${item.second_surname}`"
                        ></span>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>

                <!-- CARD EVIDENCIA -->
                <v-card class="overflow-hidden">
                  <v-toolbar color="white" flat>
                    <v-toolbar-title class="font-weight-bold grey--text">
                      EVIDENCIA
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-card-text>
                    <evidences
                      :object-id="eventId"
                      content-type="Case"
                      module="self_justice"
                      ref="evidencesComponent"
                    ></evidences>
                  </v-card-text>
                </v-card>
                <!-- CARD OBSERVACIONES FINALES -->
                <v-card class="overflow-hidden">
                  <v-toolbar color="white" flat>
                    <v-toolbar-title class="grey--text font-weight-bold">
                      OBSERVACIONES FINALES
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row class="mt-2">
                      <v-col cols="12" lg="12" md="6">
                        <v-textarea
                          v-model="newDisharmony.observation"
                          :error-messages="validationErrors.observation"
                          hint=""
                          label="Escriba el texto aquí..."
                          name="input-7-4"
                          solo
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <div class="text-center mt-2 mb-2">
                  <v-btn
                    class="mx-2"
                    color="primary"
                    rounded
                    small
                    @click="save()"
                    v-if="$can('handle_self_justice')"
                  >
                    Guardar
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item
            v-for="newType in newsTypes"
            :key="`content_${newType.id}`"
          >
            <v-card flat>
              <v-card-text>
                <news
                  @updated="showCase()"
                  :type="newType.code"
                  :type_id="newType.id"
                  :case-id="eventId"
                  :involveds="newDisharmony.affecteds"
                  :news="newDisharmony.news"
                  :territory="territory"
                ></news>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </template>
    <v-snackbar
        v-model="snackbarValidation"
        color="error"
    >
      Debe completar todos los campos obligatorios
      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbarValidation = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import {
  listReferences,
  showCase,
  typeList,
  updateEvent,
  listNewsTypes, getHeaderLines,
} from "@/services/SelfJustice";
import AffectedPeople from "./AffectedPeople.vue";
import AffectedPeopleEdit from "./AffectedPeopleEdit.vue";
import sweetalert from "sweetalert2";
import Evidences from "@/components/commonComponents/Evidences.vue";
import {email, maxLength, numeric, required} from "vuelidate/lib/validators";
import { mapState } from "vuex";
import News from "@/views/SelfJustice/components/News";
import getEventTypesIcons from "@/utils/newsTypesIcons";
import dayjs from "dayjs";
import NewsList from "@/views/SelfJustice/components/NewsList/NewsList";
import historyDocument from "@/utils/CaseHistoryDocument";
import {searchComuner} from "@/services/censusService";
import {isEmpty} from "lodash";
import Swal from "sweetalert2";
import ChangeState from "@/views/SelfJustice/components/ChangeState";
import selfJusticeStates from "@/enums/selfJusticeStates";

export default {
  props: {
    involved_type: {
      type: String,
    },
  },
  name: "editDisharmony.vue",
  data() {
    return {
      snackbarValidation : false,
      historyDialog : false,
      territory : {},
      newsTypes: [],
      id_disharmony: "",
      menu: false,
      description: "",
      dateRecep: "",
      dateDishar: "",
      menu2: false,
      timeMenu: false,
      timeMenu2: false,
      tp_identifications: [],
      municipalities: [],
      territories: [],
      other_territories: [],
      states: [],
      departments: [],
      sidewalks: [],
      zone: [],
      search: "",
      headers: [
        {
          text: "NO. IDENTIFICACIÓN",
          align: "start",
          sortable: false,
          value: "document_number",
        },
        { text: "NOMBRES", value: "name" },
        { text: "APELLIDOS", value: "lastname" },
        { text: "SEXO", value: "gender" },
        { text : "EDAD", value: 'age'},
        { text: "PUEBLO INDÍGENA", value: "indigenous_group" },
        { text: "RESGUARDO", value: "territory.descripcion" },
        { text: "ACCIÓN", value: "action", sortable: false },
      ],
      eventTypes: [],
      references_types: [],
      disharmonys_types: [],
      territoryTypes: [],
      village: [],
      newDisharmony: {
        event_type_id: "",
        event_subtype_id: "",
        reception_type_id: "",
        code : "",
        date: "",
        time: "",
        date2: "",
        time2: "",
        address: "",
        obs_address: "",
        description: "",
        observation: "",
        jurisdiction: "",
        department_id: null,
        zone_id: null,
        document_type: "",
        municipality_id: "",
        sidewalk_id: null,
        territory_id: "",
        contact_information: {
          id: "",
          name: "",
          name2: "",
          ape1: "",
          ape2: "",
          identification: "",
          phone_number: "",
          email: "",
        },
        affecteds: [],
        news: [],
        current_state: {},
        is_confidential: false,
      },
      changeDepto: false,
      eventId: "",
    };
  },
  validations() {
    return {
      ...this.dynamicValidations,
      description: { required, maxLength: maxLength(400) },
    };
  },
  methods: {
    editImplicated(item)
    {
      let affectedEditLocal = {...this.newDisharmony}
      let implicatedIndex = this.newDisharmony.affecteds.findIndex(
          (i) => {
            return i.involved_type === item.involved_type && Number(i.document_number) === Number(item.documentInitial)
          }
      );
      console.log(implicatedIndex)
      if(!Number.isInteger(item.affectedEdit.indigenous_group)){
        item.affectedEdit.indigenous_group = item.affectedEdit.indigenous_group?.pueblo_id;
      }
      affectedEditLocal.affecteds[implicatedIndex] = item.affectedEdit;
      this.newDisharmony = affectedEditLocal;
    },
    deleteItem(document_number) {
      const index = this.newDisharmony.affecteds.findIndex(
        (x) => x.document_number === document_number
      );
      if (index >= 0) {
        if (this.newDisharmony.affecteds[index]["id"] === undefined) {
          this.newDisharmony.affecteds.splice(index, 1);
        } else {
          this.newDisharmony.affecteds[index]["remove"] = true;
        }
      }
    },
    concatDateRecep() {
      this.dateRecep = `${this.newDisharmony.date} ${this.newDisharmony.time}`;
    },
    concatDateDishar() {
      this.dateDishar = `${this.newDisharmony.date2} ${this.newDisharmony.time2}`;
    },
    addAffected(affected) {
      this.newDisharmony.affecteds.push({
        ...affected,
        indigenous_group: affected.indigenous_group?.pueblo_id,
        remove: false,
        newInvolved : true
      });
    },
    async save() {
      this.$v.newDisharmony.$touch();
      if (this.$v.newDisharmony.$invalid){
        this.snackbarValidation = true;
        return;
      }

      try {
        this.showLoader();

        const eventRequestData = {
          reception_datetime: `${this.newDisharmony.date} ${this.newDisharmony.time}`,
          disharmony_datetime: `${this.newDisharmony.date2} ${this.newDisharmony.time2}`,
          reception_type: this.newDisharmony.reception_type_id,
          disharmony_id: this.newDisharmony.event_subtype_id,
          department_id: this.newDisharmony.department_id,
          zone_id: this.newDisharmony.zone_id,
          municipality_id: this.newDisharmony.municipality_id,
          territory_id: this.newDisharmony.territory_id,
          sidewalk_id: this.newDisharmony.sidewalk_id,
          address: this.newDisharmony.address,
          address_complement: this.newDisharmony.obs_address,
          affected_territories: this.other_territories,
          jurisdiction: this.newDisharmony.jurisdiction, //preguntar no esta front
          informant: {
            id: this.newDisharmony.contact_information.id,
            document_type: this.newDisharmony.document_type,
            document_number:
              this.newDisharmony.contact_information.identification === ""
                ? null
                : this.newDisharmony.contact_information.identification,
            name: this.newDisharmony.contact_information.name,
            second_name: this.newDisharmony.contact_information.name2,
            lastname: this.newDisharmony.contact_information.ape1,
            second_surname: this.newDisharmony.contact_information.ape2,
            phone_number: this.newDisharmony.contact_information.phone_number,
            email: this.newDisharmony.contact_information.email,
          },
          involved: this.newDisharmony.affecteds.map((affected) => {
            return {
              id: affected.id,
              document_number: affected.document_number === "" ? null : affected.document_number,
              involved_type: affected.involved_type,
              document_type: affected.document_type,
              name: affected.name,
              second_name: affected.second_name,
              lastname: affected.lastname,
              second_surname: affected.second_surname,
              gender: affected.gender,
              territory_id: affected.territory.territorio_id,
              indigenous_group: affected.indigenous_group,
              age : affected.age,
              remove: affected.remove,
            };
          }),
          description: this.description,
          final_observations: this.newDisharmony.observation,
          user_id: this.user.id,
          is_confidential: this.newDisharmony.is_confidential,
        };

        await updateEvent(this.eventId, eventRequestData);

        await this.$refs.evidencesComponent.upload(this.eventId);

        await sweetalert.fire("Registro actualizado correctamente", "", "success");

        this.$router.push({
          name: "selfjustice.listofdisharmonies",
        });

        this.hideLoader();
      } catch (err) {
        this.hideLoader();
        console.error(err);
        sweetalert.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    formatDateTime(date) {
      const dateReverse = date.split("/").reverse().join("-");
      return dateReverse;
    },
    separeDateTime(dateTime) {
      const dateToFormat = dayjs(dateTime);
      let date = dateToFormat.format("YYYY-MM-DD");
      let time = dateToFormat.format("HH:MM");
      return [date, time];
    },
    async showCase() {
      this.id_disharmony = this.$route.params.id;
      this.eventId = this.$route.params.id;
      const eventShowResponse = await showCase(this.id_disharmony);

      this.newDisharmony.reception_type_id =
        eventShowResponse.data.reception_type;

      var dateReceptionFormat = this.separeDateTime(
        eventShowResponse.data.reception_datetime
      );
      var dateDisharmonyFormat = this.separeDateTime(
        eventShowResponse.data.reception_datetime
      );

      this.newDisharmony.date = this.formatDateTime(dateReceptionFormat[0]);
      this.newDisharmony.time = dateReceptionFormat[1];
      this.newDisharmony.date2 = this.formatDateTime(dateDisharmonyFormat[0]);
      this.newDisharmony.time2 = dateDisharmonyFormat[1];
      this.newDisharmony.event_subtype_id =
        eventShowResponse.data.disharmony.id;
      this.newDisharmony.department_id = eventShowResponse.data.department.id;
      this.newDisharmony.zone_id = eventShowResponse.data.municipality.zona.id;
      this.newDisharmony.municipality_id = eventShowResponse.data.municipality.id;
      this.newDisharmony.territory_id = eventShowResponse.data.territory.id;
      this.newDisharmony.sidewalk_id = eventShowResponse.data.sidewalk?.id || null;
      this.newDisharmony.address = eventShowResponse.data.address;
      this.newDisharmony.obs_address =
        eventShowResponse.data.address_complement;
      this.newDisharmony.jurisdiction = eventShowResponse.data.jurisdiction;
      this.newDisharmony.is_confidential = eventShowResponse.data.is_confidential;
      this.other_territories = eventShowResponse.data.affected_territories.map(
        (x) => {
          return x.territory.id;
        }
      );
      this.newDisharmony.news = eventShowResponse.data.news;
      //informant
      this.newDisharmony.document_type = eventShowResponse.data.reporting_person.document_type;
      this.newDisharmony.contact_information.identification =
        eventShowResponse.data.reporting_person.document_number;
      this.newDisharmony.contact_information.id =
        eventShowResponse.data.reporting_person.id;
      this.newDisharmony.contact_information.name =
        eventShowResponse.data.reporting_person.name;
      this.newDisharmony.contact_information.name2 =
        eventShowResponse.data.reporting_person.second_name;
      this.newDisharmony.contact_information.ape1 =
        eventShowResponse.data.reporting_person.lastname;
      this.newDisharmony.contact_information.ape2 =
        eventShowResponse.data.reporting_person.second_surname;
      this.newDisharmony.contact_information.phone_number =
        eventShowResponse.data.reporting_person.phone_number;
      this.newDisharmony.contact_information.email =
        eventShowResponse.data.reporting_person.email;
      this.newDisharmony.current_state = eventShowResponse.data.current_state;
      this.newDisharmony.description = eventShowResponse.data.description;
      this.description = eventShowResponse.data.description;
      this.newDisharmony.observation =
        eventShowResponse.data.final_observations;

      this.territory = eventShowResponse.data.territory;

      this.newDisharmony.affecteds = eventShowResponse.data.involved_people.map(
        (x) => {
          return {
            id: x.id,
            name: x.person.name,
            involved_type: x.relationship_type,
            document_number: x.person.document_number || '',
            document_type: x.person.document_type,
            second_name: x.person.second_name,
            lastname: x.person.lastname,
            second_surname: x.person.second_surname,
            gender: x.person.genre,
            territory: {
              territorio_id: x.person.territory.id,
              codigo: x.person.territory.codigo,
              descripcion: x.person.territory.descripcion,
            },
            territorio_id: x.person.territory.id,
            indigenous_group: x.person.indigenous_group,
            citation: x.citation,
            capture_order: x.capture_order,
            penalties: x.penalties,
            penalty_tracking: x.penalty_tracking,
            victim_tracking: x.victim_tracking,
            current_citation: x.current_citation,
            remove: false,
            age : x.person.age,
          };
        }
      );

      this.newDisharmony.code = eventShowResponse.data.case_code;
    },
    getIcon(newsType) {
      return getEventTypesIcons(newsType);
    },
    async historyDocument(){
      try{
        this.showLoader();
        const response = await getHeaderLines(this.newDisharmony.territory_id);
        historyDocument({
          id : this.id_disharmony,
          ...this.newDisharmony,
          headerLines : response.data.map(x => x.line),
          dataSource : this.dataSource,
          disharmonys_types : this.disharmonys_types,
          affected_territories : this.other_territories
        })
        this.hideLoader();
      }catch (e) {
        this.hideLoader()
        console.error(e);
        Swal.fire('Error', 'Ha ocurrido un error', 'error')
      }

    },
    async searchComuner() {
      this.showLoader();
      const response = await searchComuner(this.newDisharmony.contact_information.identification);
      if (isEmpty(response.data.results)) {
        Swal.fire(
            "Info",
            "No se encontraron resultados en el censo",
            "info"
        );
      } else {
        let results = response.data.results[0];

        this.newDisharmony.contact_information.name = results.nombre1;
        this.newDisharmony.contact_information.name2 = results.nombre2;
        this.newDisharmony.contact_information.ape2 = results.apellido2;
        this.newDisharmony.contact_information.ape1 = results.apellido1;

      }

      this.hideLoader();
    },
  },
  async created() {
    this.showLoader();
    this.newsTypes = (await listNewsTypes())?.data;

    this.id_disharmony = this.$route.params.id;
    this.eventId = this.$route.params.id;
    await this.showCase();
    await this.$nextTick();
    await this.$refs.evidencesComponent.list();

    const eventTypeDishResponse = await typeList();
    this.disharmonys_types = eventTypeDishResponse.data;
    this.tp_identifications = this.dataSource.data.Tipoidentificacion;
    this.municipalities = this.dataSource.data.Municipio;
    const eventTypeRefResponse = await listReferences();
    this.references_types = eventTypeRefResponse.data;
    this.departments = this.dataSource.data.Departamento;
    this.territories = this.dataSource.data.Territorio;
    this.zone = this.dataSource.data.Zona;
    this.territoryTypes = this.dataSource.data.tipo_territorio;
    this.sidewalks = this.dataSource.data.villages;
    this.village = this.dataSource.data.Pueblo;
    this.concatDateDishar();
    this.concatDateRecep();
    this.hideLoader();
  },
  computed: {
    villagesFilter() {
      return this.dataSource.data.villages.filter(
        (x) => x.municipality_id === this.newDisharmony.municipality_id
      );
    },
    municipalitiesFilter() {
      let municipalities =  this.municipalities.filter(
        (x) => x.departamento === this.newDisharmony.department_id
      );

      if (this.newDisharmony.zone_id != null && this.newDisharmony.zone_id != "") {
        municipalities = this.municipalities.filter(
          (x) => x.zona === this.newDisharmony.zone_id
        );
      }

      return municipalities;
    },
    maxDate() {
      return new Date().toISOString().substr(0, 10);
    },
    filteredVictim() {
      let array = []
        if(this.newDisharmony.affecteds.length > 0){
          array = this.newDisharmony.affecteds.filter((i) => {
          return !i.remove && i.involved_type === "VICTIM";
        });
      }

      return array
    },
    filteredVictimize() {
      let array = []
      if(this.newDisharmony.affecteds.length > 0)
      {
        array = this.newDisharmony.affecteds.filter((i) => {
        return !i.remove && i.involved_type === "VICTIMIZE";
      });
      }
      return array;
    },
    filteredWitness() {
      let array = []
      if(this.newDisharmony.affecteds.length > 0)
      {
        array = this.newDisharmony.affecteds.filter((i) => {
        return !i.remove && i.involved_type === "WITNESS";
      });
      }
      return array;
    },
    eventSubtypes() {
      return (
        this.eventTypes.find((x) => x.id === this.newDisharmony.event_type_id)
          ?.subtypes || []
      );
    },
    requiredData() {
      return (
        this.eventTypes.find((x) => x.id === this.newDisharmony.event_type_id)
          ?.required_data || []
      );
    },
    zoneFilteredByDepartment() {
      return this.zone.filter(
        (x) => x.departamento === this.newDisharmony.department_id
      );
    },
    validationErrors() {
      return {
        reception_type_id: [
          ...[
            !this.$v.newDisharmony.reception_type_id.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        jurisdiction: [
          ...[
            !this.$v.newDisharmony.jurisdiction.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        event_subtype_id: [
          ...[
            !this.$v.newDisharmony.event_subtype_id.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        date: [
          ...[
            !this.$v.newDisharmony.date.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        time: [
          ...[
            !this.$v.newDisharmony.time.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        date2: [
          ...[
            !this.$v.newDisharmony.date2.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        time2: [
          ...[
            !this.$v.newDisharmony.time2.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        address: [
          ...[
            !this.$v.newDisharmony.address.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        description: [
          ...[
            !this.$v.description.required ? "Este campo es requerido" : "",
          ].filter((x) => x != ""),
          ...[
            !this.$v.description.maxLength ? "El campo no puede tener mas de 400 caracteres" : "",
          ].filter((x) => x != ""),
        ],
        observation: [
          ...[
            !this.$v.newDisharmony.observation.maxLength ? "El campo no puede tener mas de 400 caracteres" : "",
          ].filter((x) => x != ""),
        ],
        contact_information: {
          identification : [
            ...[
              !this.$v.newDisharmony.contact_information.identification.required && this.requiredDocumentNumber
                ? "Este campo es requerido"
                : "",
            ].filter((x) => x != ""),
          ],
          name: [
            ...[
              !this.$v.newDisharmony.contact_information.name.required
                ? "Este campo es requerido"
                : "",
            ].filter((x) => x != ""),
          ],
          ape1: [
            ...[
              !this.$v.newDisharmony.contact_information.ape1.required
                ? "Este campo es requerido"
                : "",
            ].filter((x) => x !== ""),
          ],
          phone_number: [
            ...[
              !this.$v.newDisharmony.contact_information.phone_number.required
                ? "Este campo es requerido"
                : "",
            ].filter((x) => x != ""),
            ...[
              !this.$v.newDisharmony.contact_information.phone_number.numeric
                ? "Solo se permiten numeros"
                : "",
            ].filter((x) => x != ""),
          ],
          email: [
            ...[
              !this.$v.newDisharmony.contact_information.email.required
                ? "Este campo es requerido"
                : "",
            ].filter((x) => x != ""),
            ...[
              !this.$v.newDisharmony.contact_information.email.email
                ? "El formato de correo es incorrecto"
                : "",
            ].filter((x) => x != ""),
          ],
        },
        department_id: [
          ...[
            !this.$v.newDisharmony.department_id.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        municipality_id: [
          ...[
            !this.$v.newDisharmony.municipality_id.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        territory_id: [
          ...[
            !this.$v.newDisharmony.territory_id.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
      };
    },
    requiredDocumentNumber(){

      if(this.newDisharmony.document_type === '' && this.newDisharmony.document_type !== null){
        return false;
      }

      const selectedDocumentType = this.dataSource.data.Tipoidentificacion.find(
          (x) => x.tipoidentificacion_id === this.newDisharmony.document_type
      );

      if(selectedDocumentType === undefined){
        return false;
      }

      return selectedDocumentType.codigo !== "SI";
    },
    dynamicValidations() {
      return {
        newDisharmony: {
          event_subtype_id: { required },
          reception_type_id: { required },
          date: { required },
          time: { required },
          date2: { required },
          time2: { required },
          address: { required },
          jurisdiction: { required },
          contact_information: {
            identification : this.requiredDocumentNumber ? {required} : {},
            name: { required },
            ape1: { required },
            phone_number: { required, numeric },
            email: { required, email },
          },
          department_id: { required },
          municipality_id: { required },
          territory_id: { required },
          observation: { required, maxLength: maxLength(400) },
        },
      }
    },
    ...mapState(["dataSource", "user"]),
    selfJusticeStates() {
      return selfJusticeStates;
    },
  },
  components: {ChangeState, NewsList, News, AffectedPeople, Evidences, AffectedPeopleEdit },
  watch: {
    'newDisharmony.department_id': function (newValue, oldValue) {
      if(newValue !== oldValue && oldValue !== null){
        this.newDisharmony.zone_id = null;
        this.newDisharmony.municipality_id = null;
      }
    }
  },
};
</script>

<style scoped></style>
