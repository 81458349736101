<template>
  <v-card>
    <v-card-text>
      Tipo de archivo permitido: jpg, gif, mp4, pdf, docx, doc, tiff, png. Menores a 5mb
      <file-pond
          maxFileSize="5MB"
          allow-multiple="true"
          label-idle="Arrastre aqui sus archivos, o seleccione"
          ref="filePond"
          labelMaxFileSize="El tamaño excede los 5MB"
          labelFileTypeNotAllowed="Extensión no permitida extensiones permitidas jpg, gif, mp4, pdf, docx, doc, tiff, png"
          accepted-file-types="image/jpeg, image/png, image/gif, video/mp4, application/pdf, application/msword, image/tiff"
      />
      <evidence-item
        v-for="(evidence, i) in evidences"
        :key="`evidence_${i}`"
        :evidenceItem="evidence"
      >
      </evidence-item>
    </v-card-text>
  </v-card>
</template>
<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import { store, list } from "@/services/evidenceService";
import EvidenceItem from "./EvidenceItem.vue";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
const FilePond = vueFilePond(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

export default {
  props: ["objectId", "contentType", "module"],
  data() {
    return {
      files: [],
      evidences: [],
    };
  },
  components: { FilePond, EvidenceItem },
  methods: {
    async upload() {
      try {
        const files = this.$refs.filePond.getFiles();
        let formData = new FormData();
        formData.append("object_id", this.objectId);
        formData.append("content_type", this.contentType);
        formData.append("module", this.module);
        files.forEach((file) => {
          formData.append("files", file.file);
        });

        if (files.length) await store(formData);
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async list() {
      const response = await list(this.objectId, this.contentType, this.module);
      this.evidences = response.data;
    },
    clean(){
      this.files = [];
      this.evidences = [];
      this.$refs.filePond.removeFiles();
    }
  },
};
</script>
