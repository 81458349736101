<template>
  <v-card dark color="primary" class="elevation-5 border-primary">
    <v-card-title class="text-h6">CERRAR</v-card-title>
    <v-card-text class="white text--primary pt-1">
      <p>{{ news.description }}</p>
      <v-row>
        <v-col cols="12" v-for="evidence in news.evidences" :key="evidence.id">
          <evidence-item :evidence-item="evidence"></evidence-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import evidenceItem from "@/components/commonComponents/EvidenceItem";
export default {
  components : {evidenceItem},
  props: ["news"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>
