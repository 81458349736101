<template>
  <v-dialog v-model="captureOrderDocumentDialog" width="500">
    <v-card>
      <v-card-title> Desea exportar el documento ? </v-card-title>
      <v-card-text class="pt-2 pb-2">
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded color="dark" @click="captureOrderDocumentDialog=false">Cancelar</v-btn>
        <v-btn rounded color="primary"  @click="getDocument()">Exportar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import captureOrderDocument from "@/utils/captureOrderDocument";
import {getHeaderLines} from "@/services/SelfJustice";
import Swal from "sweetalert2";

export default {
  name: "CaptureOrderDocumentDialog",
  data() {
    return {
      dialog: false,
      captureOrderData: {},
      authoritiesSelected: [],
      captureOrderDocumentDialog : false
    };
  },
  methods: {
    open(data) {
      this.captureOrderData = data;
      this.captureOrderDocumentDialog = true;
    },
    async getDocument() {
      try{
        this.showLoader();
        const response = await getHeaderLines(this.captureOrderData.territory.id);
        captureOrderDocument(
            this.captureOrderData.news,
            this.captureOrderData.captureOrder,
            this.captureOrderData.territory,
            this.captureOrderData.involved,
            this.authoritiesSelected,
            response.data.map(x => x.line)
        );

        this.hideLoader();
      }catch (e) {
        this.hideLoader()
        console.error(e);
        Swal.fire('Error', 'Ha ocurrido un error', 'error')
      }

    },
  },
  computed: {
    ...mapState(["selfJusticeReference"]),
  },
};
</script>

<style scoped></style>
