<template>
  <v-card class="overflow-hidden">
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <span class="green--text">Lugar de corrección</span>
          <v-autocomplete
            :items="selfJusticeReference.penalty_places"
            v-model="penaltyPlace"
            dense
            item-text="name"
            item-value="id"
            placeholder="Seleccione un valor"
            solo
            :error-messages="validationErrors.penaltyPlace"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="6">
          <span class="green--text">Tipo corrección</span>
          <v-autocomplete
            :items="
              selfJusticeReference.penalty_types.filter(
                (item) => item.penalty_place_id == penaltyPlace
              )
            "
            v-model="penaltyType"
            dense
            item-text="name"
            item-value="id"
            placeholder="Seleccione un valor"
            solo
            :error-messages="validationErrors.penaltyType"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="6" v-if="penaltyPlace == 5">
          <span class="green--text">Patio</span>
          <v-autocomplete
            :items="selfJusticeReference.yards"
            v-model="yard"
            dense
            item-text="name"
            item-value="id"
            placeholder="Seleccione un valor"
            solo
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <span class="green--text">Fecha de inicio</span>
          <v-menu
            v-model="menuInitDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :error-messages="validationErrors.initDate"
                v-model="initDate"
                v-bind="attrs"
                v-on="on"
                solid
                dense
              >
              </v-text-field>
            </template>
            <v-date-picker
              locale="es-co"
              v-model="initDate"
              @input="menuInitDate = false"
              solo
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <span class="green--text">Fecha de finalización</span>
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :error-messages="validationErrors.endDate"
                v-model="endDate"
                v-bind="attrs"
                v-on="on"
                solid
                dense
              >
              </v-text-field>
            </template>
            <v-date-picker
              locale="es-co"
              v-model="endDate"
              @input="menuEndDate = false"
              solo
              :min="initDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <span class="green--text"> Compromisos </span>
          <v-textarea
            solo
            dense
            v-model="commitments"
            :error-messages="validationErrors.commitments"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-data-table
        :footer-props="{
          'items-per-page-text': 'Filas por página',
          'items-per-page-options': [5,10,15]

        }"
        v-model="selected"
        :headers="headers"
        :items="involvedFiltered"
        item-key="id"
        class="mt-2"
        show-select
        show-expand
        :single-select="true"
      >
        <template v-slot:item.involved_type="{ item }">
            {{ typesInvolved[item.involved_type]}}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td colspan="4">
            <div
              v-for="penalty in item.penalties"
              :key="`${penalty.id}_2penalty`"
              class="mt-5"
            >
              <p>
                <strong>Corrección :</strong> {{ penalty.penalty_type.name }}
              </p>
              <p><strong>Lugar :</strong> {{ penalty.penalty_place.name }}</p>
              <p v-if="penalty.yard"><strong>Patio :</strong> {{ penalty.yard.name }}</p>
              <p><strong>Compromisos :</strong> {{penalty.commitments}}</p>
              <p><strong>Observaciones: </strong> {{getNews(penalty.news_id).description}}</p>
              <p><strong>Inica desde:</strong> {{ penalty.init_date }}</p>
              <p><strong>Finaliza hasta</strong>: {{ penalty.end_date }}</p>
              <p>
                <evidence-item v-for="evidence in getNews(penalty.news_id).evidences" :evidence-item="evidence" :key="evidence.id"></evidence-item>
              </p>
            </div>
          </td>
        </template>
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
            :ripple="false"
            :value="isSelected"
            @input="select($event)"
            indeterminate-icon="mdi-account-alert"
            :indeterminate="Boolean(item.penalties.length)"
            :disabled="Boolean(item.penalties.length) || Boolean(currenEdit)"
            :readonly="Boolean(item.penalties.length) || Boolean(currenEdit)"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon
            v-if="item.penalties.length && $can('handle_self_justice')"
            @click="setInvolvedData(item)"
            large
            color="primary"
            >{{ currenEdit?.id !== item.id ? "mdi-pencil-box" : "mdi-cancel" }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import EvidenceItem from "@/components/commonComponents/EvidenceItem";
import typeInvolved from "@/enums/typeInvolved";

export default {
  name: "Penalties.vue",
  components: {EvidenceItem},
  props: ["involved", "isSent", 'news'],
  validations() {
    return {
      selected: { required },
      initDate: { required },
      endDate: { required },
      penaltyType: { required },
      penaltyPlace: { required },
      commitments: { required },
    };
  },
  watch: {
    involved: function (val) {
      if (val) {
        this.resetInvolvedData();
      }
    },
  },
  methods: {
    getData() {
      this.$v.$touch();
      if (this.$v.$invalid) return false;
      return {
        involved_id: this.selected[0].id,
        init_date: this.initDate,
        end_date: this.endDate,
        penalty_type: this.penaltyType,
        yard: this.yard,
        penalty_place: this.penaltyPlace,
        commitments: this.commitments,
        ...(Boolean(this.currenEdit) && {
          penalty_id: this.currenEdit.penalties[0]?.id,
        }),
      };
    },
    setInvolvedData(item) {
      if (this.currenEdit?.id !== item.id) {
        this.initDate = item.penalties[0].init_date;
        this.endDate = item.penalties[0].end_date;
        this.penaltyPlace = item.penalties[0].penalty_place?.id;
        this.penaltyType = item.penalties[0].penalty_type?.id;
        this.yard = item.penalties[0].yard?.id;
        this.commitments = item.penalties[0].commitments;
        item.description = `Se actualizan los datos de la corrección para ${
          item.name
        } ${item.second_name || ""} ${item.lastname || ""} ${
          item.second_surname
        }`;
        this.currenEdit = item;
        this.selected = [{ id: this.currenEdit?.id }];
        this.$emit("onEdit", item);
      } else {
        this.resetInvolvedData();
      }
    },
    resetInvolvedData() {
      this.initDate = "";
      this.endDate = "";
      this.penaltyPlace = null;
      this.penaltyType = null;
      this.yard = null;
      this.commitments = "";
      this.currenEdit = null;
      this.selected = [];

      this.$emit("onReset");
    },
    clearForm() {
      this.initDate = "";
      this.endDate = "";
      this.penaltyPlace = null;
      this.penaltyType = null;
      this.yard = null;
      this.commitments = "";
      this.currenEdit = null;
      this.selected = [];
    },
    getNews(penaltyId){
      return this.news.find(news => news.id === penaltyId) || null
    }
  },
  data() {
    return {
      selected: [],
      initDate: "",
      endDate: "",
      penaltyPlace: "",
      penaltyType: "",
      yard: null,
      stateId: "",
      commitments: "",
      menuInitDate: false,
      menuEndDate: false,
      currenEdit: null,
      headers: [
        {
          text: "NO. IDENTIFICACIÓN",
          align: "start",
          sortable: false,
          value: "document_number",
        },
        { text: "NOMBRES", value: "name" },
        { text: "APELLIDOS", value: "lastname" },
        { text: "TIPO IMPLICADO", value: "involved_type" },
        { text: "", value: "action" },
      ],
    };
  },
  computed: {
    typesInvolved() {
      return typeInvolved;
    },
    validationErrors() {
      const validationObject = {
        selected: [
          ...[
            !this.$v.selected.required && this.$v.selected.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
        initDate: [
          ...[
            !this.$v.initDate.required && this.$v.initDate.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
        endDate: [
          ...[
            !this.$v.endDate.required && this.$v.endDate.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
        penaltyPlace: [
          ...[
            !this.$v.penaltyPlace.required && this.$v.penaltyPlace.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
        penaltyType: [
          ...[
            !this.$v.penaltyType.required && this.$v.penaltyType.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
        commitments: [
          ...[
            !this.$v.commitments.required && this.$v.commitments.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
      };

      for (const key in validationObject)
        validationObject[key] = validationObject[key].filter((x) => x !== "");

      return validationObject;
    },
    ...mapState(["selfJusticeReference"]),
    involvedFiltered() {
      return this.involved.filter((x) => x.involved_type === "VICTIMIZE");
    },
  },
};
</script>

<style scoped></style>
