<template>
  <v-card dark color="primary" class="elevation-5 border-primary">
    <v-card-title class="text-h6">Citacion</v-card-title>
    <v-card-text class="white text--primary pt-1">
      <v-chip label small color="green" text-color="white">
        <v-avatar left>
          <v-icon>mdi-account-circle</v-icon>
        </v-avatar>
        {{ getInvolved(news.citation.involved) }}

      </v-chip>
      <p class="mb-1 mt-2 ml-1">{{ news.description }}</p>
      <p class="mb-1 mt-2 ml-1">Estado : {{ news.citation.current_state.name }}</p>
      <p class="mb-1 mt-2 ml-1">Lugar : {{ news.citation.place }}</p>
      <small class="font-weight-bold ml-1 text-decoration-underline">Fecha : {{ news.citation.date }}</small>
      <small class="font-weight-bold ml-1 text-decoration-underline">Hora : {{ news.citation.time }}</small>
      <v-row>
        <v-col cols="12" v-for="evidence in news.evidences" :key="evidence.id">
          <evidence-item :evidence-item="evidence"></evidence-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import evidenceItem from "@/components/commonComponents/EvidenceItem";
export default {
  props: ["news", "involved"],
  components : {evidenceItem},
  data() {
    return {};
  },
  methods: {
    getInvolved(id) {
      const involved = this.involved.find((x) => x.id === id);
      if (involved)
        return `${involved.name} ${involved.lastname}`.toUpperCase();
      return "";
    }
  },
};
</script>

<style scoped></style>
