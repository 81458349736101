<template>
  <v-card dark color="primary" class="elevation-5 border-primary">
    <v-card-title class="text-h6">Seguimiento</v-card-title>
    <v-card-text class="white text--primary pt-1">
      <p>{{ news.description }}</p>
      <v-row v-if="news.penalty_tracking">
        <v-col cols="12">
          <v-chip label small color="green" text-color="white">
            <v-avatar left>
              <v-icon>mdi-account-circle</v-icon>
            </v-avatar>
            {{ getInvolved(news.penalty_tracking.involved) }}
          </v-chip>
          <p>Tipo de seguimiento : {{news.penalty_tracking.penalty_tracking_category}}</p>
        </v-col>
      </v-row>
      <v-row v-if="news.victim_tracking">
        <v-col cols="12">
          <v-chip label small color="green" text-color="white">
            <v-avatar left>
              <v-icon>mdi-account-circle</v-icon>
            </v-avatar>
            {{ getInvolved(news.victim_tracking.involved) }}
          </v-chip>
          <p>Tipo de seguimiento : {{news.victim_tracking.victim_tracking_category}}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-for="evidence in news.evidences" :key="evidence.id">
          <evidence-item :evidence-item="evidence"></evidence-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import evidenceItem from "@/components/commonComponents/EvidenceItem";
export default {
  components : {evidenceItem},
  props: ["news", 'involved'],
  data() {
    return {};
  },
  methods: {
    getInvolved(id) {
      const involved = this.involved.find((x) => x.id === id);
      if (involved)
        return `${involved.name} ${involved.lastname}`.toUpperCase();
      return "";
    }
  },
};
</script>

<style scoped></style>
