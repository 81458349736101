<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">
          <v-icon class="mr-2" small>mdi-message-reply-text-outline</v-icon>
          Registrar Respuesta
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <span class="green--text">Tipo de respuesta</span>
              <v-radio-group v-model="response.is_approve" row>
                <v-radio label="Aprobado" :value="true"></v-radio>
                <v-radio label="Desaprobado" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <span class="green--text">Concepto</span>
              <v-textarea
                v-model="response.concept"
                row
                solo
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
        <v-btn color="blue darken-1" text @click="register"> Registrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
import { storeReviewResponse } from "@/services/SelfJustice";

export default {
  data: () => ({
    dialog: false,
    datePickerMenu: "",
    entities: [],
    response: {
      review_request_id: "",
      is_approve: false,
      concept: "",
    },
  }),
  methods: {
    async open(id) {
      this.response.review_request_id = id;
      this.dialog = true;
    },
    async register() {
      try {
        this.showLoader();
        await storeReviewResponse(this.response);
        this.hideLoader();
        Swal.fire("Respuesta guardado correctamente", "", "success");
        this.$emit("updated");
        this.dialog = false;
      } catch (err) {
        this.hideLoader();
        console.error(err);
        Swal.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>
