<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-top-transition"
        :max-width="maxWidth || 600"
      >
        <template v-slot:default>
          <v-card>
            <v-toolbar color="primary" dark>
              <v-row>
                <v-col cols="10">
                  {{ modalTitle }}
                </v-col>
                <v-col cols="2" style="display: flex; justify-content: flex-end"
                  ><v-icon @click="closeModal">mdi-close</v-icon></v-col
                >
              </v-row>
            </v-toolbar>
            <v-card-text>
              <div class="text-md-body-1 mt-5">
                Fecha del segumiento: {{ newsInfo.date }}
              </div>
              <div class="text-md-body-1 mt-3">
                Decripción del seguimiento {{ newsInfo.description }}
              </div>
              <v-row>
                <v-col>
                  <evidence-item v-for="evidence in evidences" :evidence-item="evidence" :key="evidence.id"></evidence-item>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-end"></v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import EvidenceItem from "@/components/commonComponents/EvidenceItem";
export default {
  components: {EvidenceItem},
  data() {
    return {
      dialog: false,
    };
  },
  props: ["maxWidth", "modalTitle", "newsInfo", "evidences"],
  methods: {
    closeModal() {
      this.dialog = false;
    },
    openModal() {
      this.dialog = true;
    },
  },
};
</script>
