<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-dialog v-model="dialog2" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5"> Alertas de fuga </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" v-for="(newAlert, i) in filteredNews" :key="i">
                    <span class="green--text">Descripción</span>
                    <p>{{newAlert.description}}</p>
                    <small class="green--text">Fecha : {{newAlert.date | formatDate}}</small>
                    <p>
                      <evidence-item v-for="evidence in newAlert.evidences" :evidence-item="evidence" :key="evidence.id"></evidence-item>
                    </p>
                    <v-btn v-if="newAlert.review_request_alert?.active" @click="deactivateAlert(newAlert.review_request_alert?.id)" color="error" rounded small>
                      <v-icon>mdi-alert-circle</v-icon>
                      Desactivar Alerta
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="dialog2 = false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <span class="green--text">Implicados</span>
        <v-data-table
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-options': [5,10,15]

          }"
          v-model="person_involved"
          :headers="headers"
          :items="filterInvolved"
          item-key="id"
          class="mt-2"
          show-select
          show-expand
          :single-select="true"
        >
          <template v-slot:item.involved_type="{ item }">
              {{ typesInvolved[item.involved_type]}}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn color="primary" rounded small @click="findInvolved(item.id)">
              Ver mas
            </v-btn>
          </template>
          <template v-slot:item.state="{ item }">
            <v-chip outlined color="red">
              {{ item.state }}
            </v-chip>
          </template>
          <template
            v-slot:item.data-table-select="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :ripple="false"
              :value="isSelected"
              @input="select($event)"
              :readonly="Boolean(isActiveAlert(item.id))"
              :disabled="Boolean(isActiveAlert(item.id))"
              :indeterminate="Boolean(isActiveAlert(item.id))"
              indeterminate-icon="mdi-account-alert"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <request-response
      @updated="$emit('updated')"
      ref="requestResponseComponent"
    ></request-response>
    <request-response-view
      ref="requestResponseViewComponent"
    ></request-response-view>
  </v-container>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import RequestResponse from "@/views/SelfJustice/components/RequestResponse";
import RequestResponseView from "@/views/SelfJustice/components/RequestResponseView";
import EvidenceItem from "@/components/commonComponents/EvidenceItem";
import typeInvolved from "@/enums/typeInvolved";
import {deactivateAlert} from "@/services/SelfJustice";
import Swal from "sweetalert2";

export default {
  components: {EvidenceItem, RequestResponseView, RequestResponse },
  props: ["involved", "news"],
  name: "ReviewRequest.vue",
  data() {
    return {
      dialog2: false,
      headers: [
        {
          text: "NO. IDENTIFICACIÓN",
          align: "start",
          sortable: false,
          value: "document_number",
        },
        { text: "NOMBRES", value: "name" },
        { text: "APELLIDOS", value: "lastname" },
        { text: "TIPO IMPLICADO", value: "involved_type" },
        { text: "ACCIÓN", value: "action" },
      ],
      person_involved: [],
      filteredNews : []
    };
  },
  validations() {
    return {
      person_involved: { required },
    };
  },
  methods: {
    findInvolved(id) {
      this.filteredNews = this.news.filter(
        (item) => item.review_request_alert?.person_involved == id
      );
      this.dialog2 = true;
    },
    isActiveAlert(id){
      const alerts = this.news.filter(
          (item) => item.review_request_alert?.person_involved == id && item.review_request_alert?.active
      );

      return alerts.length > 0;
    },
    getData() {
      this.$v.$touch();
      if (this.$v.$invalid) return false;
      return {
        person_involved: this.person_involved[0].id,
      };
    },
    setResponse(id) {
      this.$refs.requestResponseComponent.open(id);
    },
    clearForm(){
      this.person_involved = [];
    },
    async deactivateAlert(id){
      try{
        this.showLoader();
        await deactivateAlert(id)
        this.dialog2 = false;
        Swal.fire({
          icon: "success",
          title: "Alerta desactivada",
          showConfirmButton: false,
          timer: 1500,
        });
        this.hideLoader();
        this.$emit('updated');
      }catch (e) {
        this.hideLoader();
        console.error(e)
      }
    }
  },
  computed: {
    typesInvolved() {
      return typeInvolved;
    },
    getItemText(item) {
      return `${item.name}`;
    },
    filterInvolved() {
      return this.involved.filter((x) => x.involved_type === "VICTIMIZE");
    },
    validationErrors() {
      const validationObject = {
        person_involved: [
          ...[
            !this.$v.person_involved.required && this.$v.person_involved.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
      };

      for (const key in validationObject)
        validationObject[key] = validationObject[key].filter((x) => x !== "");

      return validationObject;
    },
  },
};
</script>

<style scoped></style>
