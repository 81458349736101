<template>
  <p></p>
</template>

<script>
export default {
  name: "Close",
  methods: {
    getData() {
      return {};
    },
    clearForm(){

    }
  },
};
</script>

<style scoped></style>
