const selfJusticeStates = {
  CLOSE: "CLOSE",
  CORRECTION: "CORRECTION",
  TRACKING: "TRACKING",
  INVESTIGATION: "INVEST",
};

Object.freeze(selfJusticeStates);

export default selfJusticeStates;
