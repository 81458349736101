<template>
  <div>
    <v-card class="overflow-hidden">
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <span class="green--text">Fecha de la citación</span>
            <v-text-field
              type="date"
              v-model="date"
              :error-messages="validationErrors.date"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <span class="green--text">Hora de la citación</span>
            <v-text-field
              type="time"
              v-model="time"
              :error-messages="validationErrors.time"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <span class="green--text">Lugar</span>
            <v-text-field
              type="text"
              v-model="place"
              :error-messages="validationErrors.place"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-options': [5,10,15]

          }"
          v-model="selected"
          :headers="headers"
          :items="involved"
          item-key="id"
          class="mt-2"
          show-select
          show-expand
          :single-select="true"
        >
          <template v-slot:item.state="{ item }">
            <v-chip outlined color="red">
              {{ item.state }}
            </v-chip>
          </template>
          <template v-slot:item.involved_type="{ item }">
              {{ typesInvolved[item.involved_type]}}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pt-1">
              <v-row v-for="(citation, index) in item.citation" :key="`${citation.id}_2citation`">
                <v-col cols="12">
                  <v-row
                      class="pt-2 pr-2 pl-2"
                  >
                    <v-col cols="1" class="pt-5"> #{{ index + 1 }} </v-col>
                    <v-col cols="2" class="pt-5">
                      {{ citation.date }}
                    </v-col>
                    <v-col cols="2" class="pt-5">
                      {{ citation.time }}
                    </v-col>
                    <v-col cols="3">
                      <v-select
                          small-chips
                          dense
                          :items="selfJusticeReference.citation_states"
                          v-model="citation.current_state.id"
                          item-text="name"
                          item-value="id"
                          :readonly="
                      ((citation?.current_state || {})?.code || '') !==
                      'PROCESS'
                    "
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="1" class="pt-4">
                      <v-btn
                          v-if="citation.current_state.code === 'PROCESS' && $can('handle_self_justice')"
                          color="primary"
                          rounded
                          small
                          @click="updateState(citation.id, citation.current_state.id)"
                      >
                        <v-icon>mdi-update</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="1" class="pt-4">
                      <v-btn
                          color="primary"
                          rounded
                          small
                          @click="getDocument(citation, item)"
                      >
                        <v-icon>mdi-file-document-outline</v-icon>
                        Exportar
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3">
                      <p><strong>Lugar:</strong> {{citation.place}}</p>
                    </v-col>
                    <v-col cols="9">
                      <p><strong>Observaciones:</strong> {{getNews(citation.news_id).description}}</p>
                    </v-col>
                    <v-col cols="12">
                      <evidence-item v-for="evidence in getNews(citation.news_id).evidences" :evidence-item="evidence" :key="evidence.id"></evidence-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </td>
          </template>
          <template
            v-slot:item.data-table-select="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :ripple="false"
              :value="isSelected"
              @input="select($event)"
              indeterminate-icon="mdi-account-alert"
              :indeterminate="
                ((item.current_citation?.current_state || {})?.code || '') ===
                  'PROCESS'
              "
              :disabled="
                ((item.current_citation?.current_state || {})?.code || '') ===
                  'PROCESS'
              "
              :readonly="
                ((item.current_citation?.current_state || {})?.code || '') ===
                  'PROCESS'
              "
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <citation-document-dialog
      ref="citationDocumentDialog"
    ></citation-document-dialog>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { updateCitationState } from "@/services/SelfJustice";
import Swal from "sweetalert2";
import selfJusticeStates from "@/enums/selfJusticeStates";
//import citationDocument from "@/utils/citationDocument";
import CitationDocumentDialog from "@/views/SelfJustice/components/CitationDocumentDialog";
import EvidenceItem from "@/components/commonComponents/EvidenceItem";
import typeInvolved from "@/enums/typeInvolved";

export default {
  name: "Citation.vue",
  components: { CitationDocumentDialog, EvidenceItem},
  props: ["involved", "citations", 'news', 'territory'],
  validations() {
    return {
      selected: { required },
      date: { required },
      time: { required },
      place: { required, maxLength: maxLength(200) },
      current_citation_states: [],
    };
  },
  methods: {
    getData() {
      this.$v.$touch();
      if (this.$v.$invalid) return false;
      return {
        involved_id: this.selected[0].id,
        date: this.date,
        time: this.time,
        place: this.place,
      };
    },
    async updateState(citationId, stateId) {
      try {
        this.showLoader();
        await updateCitationState({
          citation_id: citationId,
          state_id: stateId,
        });
        Swal.fire("Estado actualizado correctamente", "", "success");
        this.hideLoader();
        this.$emit("updated");
      } catch (e) {
        console.error(e);
        Swal.fire(
          "Error",
          "Ocurrio un error al procesar la solicitud",
          "error"
        );
      }
    },
    getInvolved(id) {
      const involved = this.involved.find((x) => x.id === id);
      if (involved)
        return `${involved.name} ${involved.lastname}`.toUpperCase();
      return "";
    },
    getDocument(citation, involved) {
      this.$refs.citationDocumentDialog.open({
        citation: citation,
        involved: this.getInvolved(involved.id),
        territory : this.territory
      });
    },
    getNews(id){
      return this.news.find(news => news.id === id) || null
    },
    clearForm(){
      this.selected = [];
      this.date = "";
      this.time = "";
      this.place =  ""
    },
  },
  data() {
    return {
      selected: [],
      date: "",
      time: "",
      place: "",
      stateId: "",
      headers: [
        {
          text: "NO. IDENTIFICACIÓN",
          align: "start",
          sortable: false,
          value: "document_number",
        },
        { text: "NOMBRES", value: "name" },
        { text: "APELLIDOS", value: "lastname" },
        { text: "TIPO IMPLICADO", value: "involved_type" },
      ],
      citationDocumentDialog: false,
    };
  },
  computed: {
    typesInvolved() {
      return typeInvolved;
    },
    validationErrors() {
      const validationObject = {
        selected: [
          ...[
            !this.$v.selected.required && this.$v.selected.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
        date: [
          ...[
            !this.$v.date.required && this.$v.date.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
        place: [
          ...[
            !this.$v.place.required && this.$v.place.$dirty
              ? "Este campo es requerido"
              : "",
          ],
          ...[
            !this.$v.place.maxLength && this.$v.place.$dirty
              ? "No puede exeder los 200 caracteres"
              : "",
          ],
        ],
        time: [
          ...[
            !this.$v.time.required && this.$v.time.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
      };

      for (const key in validationObject)
        validationObject[key] = validationObject[key].filter((x) => x !== "");

      return validationObject;
    },
    ...mapState(["selfJusticeReference"]),
    states() {
      return selfJusticeStates;
    },
  },
};
</script>

<style scoped>
.v-text-field--box .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
</style>
