<template>
  <v-card class="overflow-hidden">
    <v-card-text>
      <v-tabs v-model="tabs">
        <v-tab href="#victim"> Desarmonizados </v-tab>
        <v-tab-item value="victim">
          <br />
          <v-data-table
              :footer-props="{
              'items-per-page-text': 'Filas por página',
                    'items-per-page-options': [5,10,15]
              
            }"
              v-model="selected"
              :headers="victimHeaders"
              :items="involvedFilteredVictim"
              item-key="id"
              class="mt-2"
              show-select
              show-expand
              :single-select="true"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td colspan="12">
                <v-row>
                  <v-col>
                    <div class="mt-5">
                      <strong style="color: #43794e" class="ml-6"
                      >Ruta de segumiento</strong
                      >
                      <v-breadcrumbs
                          :items="mapVictimTracking(item.victim_tracking)"
                      >
                        <template v-slot:item="{ item }">
                          <span
                              style="color: #43794e; cursor: pointer"
                              @click="getNewsVictim(item)"
                          >
                            {{ item.text }}</span
                          >
                        </template>
                        <template v-slot:divider>
                          <v-icon>mdi-chevron-right</v-icon>
                        </template>
                      </v-breadcrumbs>
                    </div>
                  </v-col>
                </v-row>
              </td>
            </template>
            <template
                v-slot:item.data-table-select="{ item, isSelected, select }"
            >
              <v-simple-checkbox
                  :ripple="false"
                  :value="isSelected"
                  @input="
                  select($event);
                  handlePenalty(item, $event);
                "
                  indeterminate-icon="mdi-account-alert"
              ></v-simple-checkbox>
            </template>
            <template v-slot:item.penalty="{ item }">
              <v-chip color="warning">{{
                  item.penalties[0]?.penalty_type?.name
                }}</v-chip>
            </template>
            <template v-slot:item.involved_type="{ item }">
              {{ typesInvolved[item.involved_type]}}
            </template>
          </v-data-table>
          <v-row>
            <v-col cols="12">
              <span class="green--text">Tipo de seguimiento</span>
              <v-autocomplete
                :items="this.categoriesVictim"
                v-model="penaltyTrackingType"
                dense
                item-text="name"
                item-value="id"
                placeholder="Seleccione un valor"
                solo
                :error-messages="validationErrors.penaltyTrackingType"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab href="#victimize"> Desarmonizantes </v-tab>
        <v-tab-item value="victimize">
          <br />
          <v-data-table
            :footer-props="{
              'items-per-page-text': 'Filas por página',
                    'items-per-page-options': [5,10,15]

            }"
            v-model="selected"
            :headers="headers"
            :items="involvedFilteredVictimize"
            item-key="id"
            class="mt-2"
            show-select
            show-expand
            :single-select="true"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td colspan="12">
                <v-row>
                  <v-col>
                    <div
                      v-for="penalty in item.penalties"
                      :key="`${penalty.id}_2penalty`"
                      class="mt-5"
                    >
                      <p>
                        <strong>Corrección :</strong>
                        {{ penalty.penalty_type.name }}
                      </p>
                      <p>
                        <strong>Lugar :</strong>
                        {{ penalty.penalty_place.name }}
                      </p>
                      <p>
                        <strong>Inica desde:</strong> {{ penalty.init_date }}
                      </p>
                      <p>
                        <strong>Finaliza hasta</strong>: {{ penalty.end_date }}
                      </p>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="mt-5">
                      <strong style="color: #43794e" class="ml-6"
                        >Ruta de segumiento</strong
                      >
                      <v-breadcrumbs
                        :items="mapTracking(item.penalty_tracking)"
                      >
                        <template v-slot:item="{ item }">
                          <span
                            style="color: #43794e; cursor: pointer"
                            @click="getNews(item)"
                          >
                            {{ item.text }}</span
                          >
                        </template>
                        <template v-slot:divider>
                          <v-icon>mdi-chevron-right</v-icon>
                        </template>
                      </v-breadcrumbs>
                    </div>
                  </v-col>
                </v-row>
              </td>
            </template>
            <template
              v-slot:item.data-table-select="{ item, isSelected, select }"
            >
              <v-simple-checkbox
                :ripple="false"
                :value="isSelected"
                @input="
                  select($event);
                  handlePenalty(item, $event);
                "
                indeterminate-icon="mdi-account-alert"
              ></v-simple-checkbox>
            </template>
            <template v-slot:item.penalty="{ item }">
              <v-chip color="warning">{{
                item.penalties[0]?.penalty_type?.name
              }}</v-chip>
            </template>
            <template v-slot:item.involved_type="{ item }">
              {{ typesInvolved[item.involved_type]}}
            </template>
          </v-data-table>
          <v-row>
            <v-col cols="12">
              <span class="green--text">Tipo de seguimiento</span>
              <v-autocomplete
                  :items="this.categoriesVictimize"
                  v-model="penaltyTrackingType"
                  dense
                  item-text="name"
                  item-value="id"
                  placeholder="Seleccione un valor"
                  solo
                  :error-messages="validationErrors.penaltyTrackingType"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
    <NewsInfoModal ref="newsInfo" v-bind="modalProps"></NewsInfoModal>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import NewsInfoModal from "@/views/SelfJustice/components/NewsInfoModal";
import typeInvolved from "@/enums/typeInvolved";

export default {
  name: "PenaltyTracking.vue",
  props: ["involved", "isSent", "news"],
  components: { NewsInfoModal },
  validations() {
    return {
      selected: { required },
      penaltyTrackingType: { required },
    };
  },
  methods: {
    getData() {
      this.$v.$touch();
      if (this.$v.$invalid) return false;
      return {
        tabs: this.tabs,
        involved_id: this.selected[0].id,
        penalty_tracking_type: this.penaltyTrackingType,
        penalty: this.currentPenalty,
      };
    },
    handlePenalty(item, event) {
      this.currentPenalty = event ? item.penalties[0]?.id : null;
      this.categoriesVictimize = event ? item.penalties[0]?.penalty_place?.id : null;
    },
    mapTracking(tracking) {
      return (
        tracking?.map((x) => ({
          ...x,
          text: x.penalty_tracking_category.name,
          href: "#",
        })) || []
      );
    },
    mapVictimTracking(tracking) {
      return (
        tracking?.map((x) => ({
          ...x,
          text: x.victim_tracking_category,
          href: "#",
        })) || []
      );
    },
    getNews(item) {
      this.modalProps.modalTitle = `${item.penalty.penalty_type.name} en ${item.penalty.penalty_place.name} segumiento de ${item.text} `;
      this.modalProps.newsInfo = {};
      this.modalProps.evidences = this.getNewsEvidences(item.news.id);
      this.$nextTick(() => {
        this.modalProps.newsInfo = item.news;
        this.$refs.newsInfo.openModal();
      });
    },
    getNewsVictim(item) {
      this.modalProps.modalTitle = `seguimiento de ${item.text} `;
      this.modalProps.newsInfo = {};
      this.modalProps.evidences = this.getNewsEvidences(item.news.id);
      this.$nextTick(() => {
        this.modalProps.newsInfo = item.news;
        this.$refs.newsInfo.openModal();
      });
    },
    getNewsEvidences(id) {
      return this.news.find(news => news.id === id)?.evidences || []
    },
    clearForm(){
      this.selected = []
      this.penaltyTrackingType = null
    }
  },
  data() {
    return {
      tabs: "",
      selected: [],
      penaltyTrackingType: "",
      penaltyCategories: [],
      currentPenalty: null,
      modalProps: {
        modalTitle: "",
        newsInfo: {},
        evidences : []
      },
      headers: [
        {
          text: "NO. IDENTIFICACIÓN",
          align: "start",
          sortable: false,
          value: "document_number",
        },
        { text: "NOMBRES", value: "name" },
        { text: "APELLIDOS", value: "lastname" },
        { text: "TIPO IMPLICADO", value: "involved_type" },
        { text: "TIPO DE CORRECCIÓN", value: "penalty" },
      ],
      victimHeaders: [
        {
          text: "NO. IDENTIFICACIÓN",
          align: "start",
          sortable: false,
          value: "document_number",
        },
        { text: "NOMBRES", value: "name" },
        { text: "APELLIDOS", value: "lastname" },
        { text: "TIPO IMPLICADO", value: "involved_type" },
      ],
    };
  },
  computed: {
    typesInvolved() {
      return typeInvolved;
    },
    validationErrors() {
      const validationObject = {
        selected: [
          ...[
            !this.$v.selected.required && this.$v.selected.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
        penaltyTrackingType: [
          ...[
            !this.$v.penaltyTrackingType.required &&
            this.$v.penaltyTrackingType.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
      };

      for (const key in validationObject)
        validationObject[key] = validationObject[key].filter((x) => x !== "");

      return validationObject;
    },
    ...mapState(["selfJusticeReference"]),
    involvedFilteredVictimize() {
      return this.involved.filter((x) => x.involved_type === "VICTIMIZE");
    },
    involvedFilteredVictim() {
      return this.involved.filter((x) => x.involved_type === "VICTIM");
    },
    categoriesVictimize: {
      get() {
        return this.penaltyCategories;
      },
      set(place) {
        this.penaltyCategories =
          this.selfJusticeReference.penalty_tracking_categories.filter(
            (x) => x.penalty_place?.id === place
          );
      },
    },
    categoriesVictim() {
      return this.selfJusticeReference.victim_tracking_categories;
    },
  },
};
</script>

<style scoped></style>
