<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <span class="green--text">Cedula del solicitante</span>
        <v-text-field
          solo
          dense
          type="text"
          v-model="requester_document"
          :error-messages="validationErrors.requester_document"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <span class="green--text">Nombre completo del solicitante</span>
        <v-text-field
          solo
          dense
          type="text"
          v-model="requester_name"
          :error-messages="validationErrors.requester_name"
        ></v-text-field>
      </v-col>
    </v-row>
    <request-response
      @updated="$emit('updated')"
      ref="requestResponseComponent"
    ></request-response>
    <request-response-view
      ref="requestResponseViewComponent"
    ></request-response-view>
  </v-container>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import RequestResponse from "@/views/SelfJustice/components/RequestResponse";
import RequestResponseView from "@/views/SelfJustice/components/RequestResponseView";
export default {
  components: { RequestResponseView, RequestResponse },
  props: ["requests"],
  name: "ReviewRequest.vue",
  data() {
    return {
      requester_document: "",
      requester_name: "",
    };
  },
  validations() {
    return {
      requester_document: { required },
      requester_name: { required },
    };
  },
  methods: {
    getData() {
      this.$v.$touch();
      if (this.$v.$invalid) return false;
      return {
        requester_document: this.requester_document,
        requester_name: this.requester_name,
      };
    },
    setResponse(id) {
      this.$refs.requestResponseComponent.open(id);
    },
    clearForm(){
      this.requester_document = "";
      this.requester_name = "";
    }
  },
  computed: {
    validationErrors() {
      const validationObject = {
        requester_document: [
          ...[
            !this.$v.requester_document.required &&
            this.$v.requester_document.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
        requester_name: [
          ...[
            !this.$v.requester_name.required && this.$v.requester_name.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
      };

      for (const key in validationObject)
        validationObject[key] = validationObject[key].filter((x) => x !== "");

      return validationObject;
    },
  },
};
</script>

<style scoped></style>
