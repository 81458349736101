import { jsPDF } from "jspdf";
import {centeredText} from "@/utils/utilsPDF";


const makeHeader = (lines, startPos, doc) => {
  const lineHeight = 4;
  lines.forEach((line) => {
    centeredText(line, startPos, doc);
    startPos += lineHeight;
  });
};



export default function citationDocument(citation, involved, authorities, headers = []) {
  const doc = new jsPDF();
  const x_default_pos = 20;
  doc.setFont("Helvetica");
  doc.setFontSize(8);

  const headerLines = headers;

  makeHeader(headerLines, 10, doc);

  doc.setFontSize(14);

  centeredText(`CITACION No. ${citation.id}`, 35, doc);

  doc.setFontSize(12);

  doc.text("CORDIAL SALUDO", x_default_pos, 45);
  doc.text(`Señor(a) : ${involved.toUpperCase()}`, x_default_pos, 60);

  const text =
    "La autoridad ancestral del territorio, le solicita comedidamente se haga presente de carácter urgente él.";
  const splitText = doc.splitTextToSize(text, 180);
  doc.text(x_default_pos, 65, splitText);

  const citationData = [
    `Fecha: ${citation.date}`,
    `Hora: ${citation.time}`,
    `Lugar: ${citation.place}`,
  ];

  let startPos = 80;
  citationData.forEach((line) => {
    doc.text(line, x_default_pos, startPos);
    startPos += 5;
  });

  startPos = 120;

  const signatures = [{
    name : 'Nombre Autoridad',
    document : 'CC. No.',
    signature : 'Firma'
  },
    {
      name : 'Nombre Autoridad',
      document : 'CC. No.',
      signature : 'Firma'
    },
    {
      name : 'Nombre Autoridad',
      document : 'CC. No.',
      signature : 'Firma'
    }
  ];

  signatures.forEach((line) => {
    doc.text(line.signature, x_default_pos, startPos);
    doc.line(x_default_pos + 15, startPos, 120, startPos);
    doc.text(line.name, x_default_pos, startPos + 10);
    doc.line(x_default_pos + 35, startPos + 10, 120, startPos + 10);
    doc.text(line.document, x_default_pos, startPos + 20);
    doc.line(x_default_pos + 15, startPos + 20, 120, startPos + 20);
    startPos += 35;
  });

  doc.setFontSize(10);

  centeredText(
    "Se envian 2 boletas de citación a la tercera será conducido por la autoridad y se armonizará por desacato",
    startPos + 20,
    doc
  );

  //open pdf in new tab
    doc.output("dataurlnewwindow");
}
