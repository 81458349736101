<template>
  <v-card dark color="primary" class="elevation-5 border-primary">
    <v-card-title class="text-h6">Solicitud de revision</v-card-title>
    <v-card-text class="white text--primary pt-1">
      <strong class="d-block green--text ml-1">Solicitud de revisión</strong>
      <p class="text-justify mb-1 mt-2 ml-1">{{ news.description }}</p>
      <strong class="d-block ml-1"
        >Nombre : {{ news.review_request.requester_name }}</strong
      >
      <strong class="d-block ml-1">Cedula : {{ news.review_request.requester_document }}</strong>
      <v-row>
        <v-col cols="12" v-for="evidence in news.evidences" :key="evidence.id">
          <evidence-item :evidence-item="evidence"></evidence-item>
        </v-col>
      </v-row>
    </v-card-text>
    <request-response-view
      ref="requestResponseViewComponent"
    ></request-response-view>
  </v-card>
</template>

<script>
import RequestResponseView from "@/views/SelfJustice/components/RequestResponseView";
import evidenceItem from "@/components/commonComponents/EvidenceItem";
export default {
  components: { RequestResponseView, evidenceItem},
  props: ["news"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>
