var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"overflow-hidden"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"green--text"},[_vm._v("Fecha de la citación")]),_c('v-text-field',{attrs:{"type":"date","error-messages":_vm.validationErrors.date},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"green--text"},[_vm._v("Hora de la citación")]),_c('v-text-field',{attrs:{"type":"time","error-messages":_vm.validationErrors.time},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"green--text"},[_vm._v("Lugar")]),_c('v-text-field',{attrs:{"type":"text","error-messages":_vm.validationErrors.place},model:{value:(_vm.place),callback:function ($$v) {_vm.place=$$v},expression:"place"}})],1)],1),_c('v-data-table',{staticClass:"mt-2",attrs:{"footer-props":{
          'items-per-page-text': 'Filas por página',
          'items-per-page-options': [5,10,15]

        },"headers":_vm.headers,"items":_vm.involved,"item-key":"id","show-select":"","show-expand":"","single-select":true},scopedSlots:_vm._u([{key:"item.state",fn:function({ item }){return [_c('v-chip',{attrs:{"outlined":"","color":"red"}},[_vm._v(" "+_vm._s(item.state)+" ")])]}},{key:"item.involved_type",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.typesInvolved[item.involved_type])+" ")]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticClass:"pt-1",attrs:{"colspan":headers.length}},_vm._l((item.citation),function(citation,index){return _c('v-row',{key:`${citation.id}_2citation`},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pt-2 pr-2 pl-2"},[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"1"}},[_vm._v(" #"+_vm._s(index + 1)+" ")]),_c('v-col',{staticClass:"pt-5",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(citation.date)+" ")]),_c('v-col',{staticClass:"pt-5",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(citation.time)+" ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"small-chips":"","dense":"","items":_vm.selfJusticeReference.citation_states,"item-text":"name","item-value":"id","readonly":((citation?.current_state || {})?.code || '') !==
                    'PROCESS'},model:{value:(citation.current_state.id),callback:function ($$v) {_vm.$set(citation.current_state, "id", $$v)},expression:"citation.current_state.id"}})],1),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"1"}},[(citation.current_state.code === 'PROCESS' && _vm.$can('handle_self_justice'))?_c('v-btn',{attrs:{"color":"primary","rounded":"","small":""},on:{"click":function($event){return _vm.updateState(citation.id, citation.current_state.id)}}},[_c('v-icon',[_vm._v("mdi-update")])],1):_vm._e()],1),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"primary","rounded":"","small":""},on:{"click":function($event){return _vm.getDocument(citation, item)}}},[_c('v-icon',[_vm._v("mdi-file-document-outline")]),_vm._v(" Exportar ")],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('p',[_c('strong',[_vm._v("Lugar:")]),_vm._v(" "+_vm._s(citation.place))])]),_c('v-col',{attrs:{"cols":"9"}},[_c('p',[_c('strong',[_vm._v("Observaciones:")]),_vm._v(" "+_vm._s(_vm.getNews(citation.news_id).description))])]),_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.getNews(citation.news_id).evidences),function(evidence){return _c('evidence-item',{key:evidence.id,attrs:{"evidence-item":evidence}})}),1)],1)],1)],1)}),1)]}},{key:"item.data-table-select",fn:function({ item, isSelected, select }){return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":isSelected,"indeterminate-icon":"mdi-account-alert","indeterminate":((item.current_citation?.current_state || {})?.code || '') ===
                'PROCESS',"disabled":((item.current_citation?.current_state || {})?.code || '') ===
                'PROCESS',"readonly":((item.current_citation?.current_state || {})?.code || '') ===
                'PROCESS'},on:{"input":function($event){return select($event)}}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('citation-document-dialog',{ref:"citationDocumentDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }