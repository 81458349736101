<template>
  <v-container>
    <v-row>
      <v-dialog v-model="dialog2" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5"> Información orden de captura </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <span class="green--text">Fecha</span>
                  <v-text-field
                    :disabled="true"
                    color="white"
                    :value="captureOrderAct.date"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <span class="green--text">Motivo de orden</span>
                  <v-text-field
                    :disabled="true"
                    color="white"
                    :value="captureOrderAct.description"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <evidence-item v-for="(item, i) in evidences" :evidence-item="item" :key="i"></evidence-item>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="
                dialog2 = false;
                captureOrderAct = [];
              "
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-card class="overflow-hidden">
      <v-card-text>
        <v-chip
          small
          outlined
          color="red"
          v-for="(error, index) in validationErrors.selected"
          :key="`${index}_error_msg`"
        >
          {{ error }}
        </v-chip>
        <v-data-table
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-options': [5,10,15]

          }"
          v-model="selected"
          :headers="headers"
          :items="involvedFiltered"
          item-key="id"
          class="mt-2"
          show-select
          show-expand
          :single-select="true"
        >
          <template v-slot:item.involved_type="{ item }">
              {{ typesInvolved[item.involved_type]}}
          </template>
          <template v-slot:item.state="{ item }">
            <v-chip outlined color="red">
              {{ item.state }}
            </v-chip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pt-1">
              <v-row
                v-for="cap in item.capture_order"
                :key="`${cap.id}_capture_order`"
                class="pa-1"
              >
                <v-col cols="3" class="pt-5"> ORDEN DE CAPTURA </v-col>
                <v-col cols="3">
                  <v-select
                    small-chips
                    dense
                    :items="selfJusticeReference.capture_order_states"
                    v-model="cap.current_state.id"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col cols="3" class="pt-4">
                  <v-btn
                    color="primary"
                    rounded
                    small
                    v-if="$can('handle_self_justice')"
                    @click="updateState(cap.id, cap.current_state.id)"
                  >
                    <v-icon>mdi-update</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="1" class="pt-4">
                  <v-btn
                    color="primary"
                    rounded
                    small
                    @click="findInvolved(item.id)"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="1" class="pt-4">
                  <v-btn
                      color="primary"
                      rounded
                      small
                      @click="getDocument(item, cap)"
                      title="Descargar documento de orden de captura"
                  >
                    <v-icon>mdi-file-account</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </td>
          </template>
          <template
            v-slot:item.data-table-select="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :ripple="false"
              :value="isSelected"
              :readonly="Boolean(item.capture_order.length)"
              :disabled="Boolean(item.capture_order.length)"
              :indeterminate="Boolean(item.capture_order.length)"
              indeterminate-icon="mdi-account-lock"
              color="primary"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card class="overflow-hidden">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <span class="green--text">Lugar de expedición del documento</span>
            <v-text-field
                solo
                dense
                color="white"
                v-model="expedition_place"
                :error-messages="validationErrors.expedition_place"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <span class="green--text">Caracteristicas Fisicas</span>
            <!-- textarea -->
            <v-textarea
                solo
                dense
                color="white"
                v-model="physical_characteristics"
                :error-messages="validationErrors.physical_characteristics"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <span class="green--text">Foto</span>
            <file-pond label-idle="Arrastre aqui sus archivos, o seleccione" accepted-file-types="image/jpeg, image/png" labelFileTypeNotAllowed="Extensión no permitida" ref="imageComponent" v-model="involved_photo" ></file-pond>
            <span class="red--text" v-for="error in validationErrors.involved_photo" :key="error">{{error}}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <capture-order-document-dialog ref="captureOrderDocumentDialog"></capture-order-document-dialog>
  </v-container>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import captureOrderStates from "@/enums/captureOrderStates";
import { mapState } from "vuex";
import { updateCaptureOrderState } from "@/services/SelfJustice";
import Swal from "sweetalert2";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import FilePond
import vueFilePond from 'vue-filepond';

// Import plugins
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import CaptureOrderDocumentDialog from "@/views/SelfJustice/components/CaptureOrderDocument";
import EvidenceItem from "@/components/commonComponents/EvidenceItem";
import {list} from "@/services/evidenceService";
import typeInvolved from "@/enums/typeInvolved";

// Create FilePond component
const FilePond = vueFilePond(FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);

export default {
  name: "CaptureOrder.vue",
  props: ["involved", "capture_orders", "territory"],
  components : {
    EvidenceItem,
    CaptureOrderDocumentDialog,
    FilePond
  },
  validations() {
    return {
      selected: { required},
      involved_photo : { required },
      physical_characteristics : { required },
      expedition_place : { required }
    };
  },
  methods: {
    getData() {
      this.$v.$touch();
      if (this.$v.$invalid) return false;
      return {
        involved_id: this.selected[0].id,
        involved_photo: this.involved_photo[0].getFileEncodeBase64String(),
        physical_characteristics: this.physical_characteristics,
        expedition_place: this.expedition_place
      };
    },
    getInvolved(id) {
      const involved = this.involved.find((x) => x.id === id);
      if (involved)
        return `${involved.name} ${involved.lastname}`.toUpperCase();
      return "";
    },
    async findInvolved(id) {
      this.dialog2 = true;
      this.captureOrderAct = this.capture_orders.find(
        (item) => item.capture_order.involved === id
      );

      const response = await list(this.captureOrderAct.id, 'News', 'self_justice');
      this.evidences = response.data;
    },
    async updateState(captureOrderId, stateId) {
      try {
        this.showLoader();
        await updateCaptureOrderState({
          capture_order_id: captureOrderId,
          status_id: stateId,
        });
        Swal.fire("Registro actualizado correctamente", "", "success");
        this.hideLoader();
        this.$emit("updated");
      } catch (e) {
        console.error(e);
        Swal.fire(
          "Error",
          "Ocurrio un error al procesar la solicitud",
          "error"
        );
      }
    },
    getDocument(item, captureOrder) {
      const news = this.capture_orders.find((x) => x.capture_order.id === captureOrder.id);
      this.$refs.captureOrderDocumentDialog.open({
        news : news,
        captureOrder : captureOrder,
        involved : item,
        territory : this.territory
      })
    },
    clearForm(){
      this.selected = [];
      this.involved_photo = [];
      this.physical_characteristics = "";
      this.expedition_place = "";
      this.$refs.imageComponent.removeFiles();
    }
  },
  data() {
    return {
      dialog2: false,
      captureOrderAct: [],
      selected: [],
      involved_photo: "",
      physical_characteristics: "",
      expedition_place: "",
      evidences : [],
      headers: [
        {
          text: "NO. IDENTIFICACIÓN",
          align: "start",
          sortable: false,
          value: "document_number",
        },
        { text: "NOMBRES", value: "name" },
        { text: "APELLIDOS", value: "lastname" },
        { text: "TIPO IMPLICADO", value: "involved_type" },
      ],
    };
  },
  computed: {
    typesInvolved() {
      return typeInvolved;
    },
    validationErrors() {
      const validationObject = {
        selected: [
          ...[
            !this.$v.selected.required && this.$v.selected.$dirty
              ? "Debe elegir un implicado"
              : "",
          ],
        ],
        involved_photo: [
          ...[
            !this.$v.involved_photo.required && this.$v.involved_photo.$dirty
              ? "Debe elegir una foto"
              : "",
          ],
        ],
        physical_characteristics: [
          ...[
            !this.$v.physical_characteristics.required &&
            this.$v.physical_characteristics.$dirty
              ? "Debe describir las caracteristicas fisicas"
              : "",
          ],
        ],
        expedition_place: [
          ...[
            !this.$v.expedition_place.required &&
            this.$v.expedition_place.$dirty
              ? "Debe indicar el lugar de expedición"
              : "",
          ],
        ],
      };

      for (const key in validationObject)
        validationObject[key] = validationObject[key].filter((x) => x !== "");

      return validationObject;
    },
    captureOrderStatus() {
      return captureOrderStates;
    },
    ...mapState(["selfJusticeReference"]),
    involvedFiltered() {
      return this.involved.filter((x) => x.involved_type === "VICTIMIZE");
    },
  },
};
</script>

<style scoped></style>
