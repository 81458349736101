<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          class="mx-2"
          color="primary"
          outlined
          rounded
          small
          v-bind="attrs"
          v-on="on"
      >
        Añadir
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Información del afectado</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <span class="green--text">Tipo de Documento</span>
              <v-autocomplete
                  v-model="affected.document_type"
                  :error-messages="validationErrors.document_type"
                  :items="dataSource.data.Tipoidentificacion"
                  dense
                  item-text="descripcion"
                  item-value="tipoidentificacion_id"
                  placeholder="Seleccione un valor"
                  solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <span class="green--text">Número de Documento</span>
              <v-text-field
                  v-model="affected.document_number"
                  :error-messages="validationErrors.document_number"
                  dense
                  hint="Presione enter para buscar en censo"
                  label="Numero de documento"
                  required
                  solo
                  type="number"
                  :readonly="!this.requiredDocumentNumber"
                  @keyup.enter="searchComuner()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <span class="green--text">Nombre</span>
              <v-text-field
                  v-model="affected.name"
                  :error-messages="validationErrors.name"
                  dense
                  label="Nombre"
                  required
                  solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <span class="green--text">Segundo Nombre</span>
              <v-text-field
                  v-model="affected.second_name"
                  dense
                  label="Segundo Nombre"
                  required
                  solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <span class="green--text">Apellido</span>
              <v-text-field
                  v-model="affected.lastname"
                  :error-messages="validationErrors.lastname"
                  dense
                  label="Apellido"
                  solo
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <span class="green--text">Segundo Apellido</span>
              <v-text-field
                  v-model="affected.second_surname"
                  dense
                  label="Segundo Apellido"
                  solo
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <span class="green--text">Genero</span>
              <v-autocomplete
                  v-model="affected.gender"
                  :error-messages="validationErrors.gender"
                  :items="genres"
                  dense
                  item-text="description"
                  item-value="code"
                  label="Sexo"
                  placeholder="Seleccione un valor"
                  solo
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <span class="green--text">Edad</span>
              <v-text-field
                  v-model="affected.age"
                  :error-messages="validationErrors.age"
                  dense
                  label="Edad"
                  solo
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="5">
              <span class="green--text">Territorio</span>
              <v-autocomplete
                  v-model="affected.territory"
                  :error-messages="validationErrors.territory"
                  :items="dataSource.data.Territorio"
                  dense
                  item-text="descripcion"
                  item-value="territorio_id"
                  label="Territorio"
                  placeholder="Seleccione un valor"
                  return-object
                  solo
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="green--text">Grupo Indígena</span>
              <v-autocomplete
                  v-model="affected.indigenous_group"
                  :error-messages="validationErrors.indigenous_group"
                  :items="dataSource.data.Pueblo"
                  dense
                  item-text="descripcion"
                  item-value="pueblo_id"
                  label="Grupo Indígena"
                  placeholder="Seleccione un valor"
                  return-object
                  solo
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
        <v-btn color="blue darken-1" text @click="add"> Agregar</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      absolute
      tile
      color="red accent-2"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>
<script>
import {mapState} from "vuex";
import {maxLength, numeric, required} from "vuelidate/lib/validators";
import {searchComuner} from "@/services/censusService";
import {isEmpty} from "lodash";
import Swal from "sweetalert2";

export default {
  props: {
    involved_type: {
      type: String,
    },
    affecteds: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      snackbar: false,
      text: `Ya existe una persona registrada para este evento`,
      dialog: false,
      genres: [
        {code: "F", description: "Femenino"},
        {code: "M", description: "Masculino"},
      ],
      affected: {
        document_number: "",
        document_type: "",
        name: "",
        second_name: "",
        lastname: "",
        second_surname: "",
        gender: "",
        territory: "",
        indigenous_group: "",
        involved_type: "",
      },
    };
  },
  validations() {
    return {
      ...this.dynamicValidations
    };
  },
  created() {
    this.dataSource.data.Territorio.unshift({
      descripcion: "NINGUNO",
      territorio_id: null,
    });
    this.dataSource.data.Territorio = this.dataSource.data.Territorio.filter(
        (item) => {
          return item.descripcion != " ";
        }
    );
    this.dataSource.data.Pueblo.unshift({
      descripcion: "NINGUNO",
      pueblo_id: null,
    });
    this.dataSource.data.Pueblo = this.dataSource.data.Pueblo.filter((item) => {
      return item.descripcion != " ";
    });
  },
  methods: {
    add() {
      console.log(String(this.affected.document_number));
      if(this.affecteds.filter((item) => String(item.document_number) === String(this.affected.document_number)).length > 0)
      {
        this.snackbar = true;
        return;
      }

      this.affected.involved_type = this.involved_type;
      this.$v.affected.$touch();
      if (this.$v.affected.$invalid) return;
      this.$emit("affected", {...this.affected});
      this.affected = {
        document_number: "",
        document_type: "",
        name: "",
        second_name: "",
        lastname: "",
        second_surname: "",
        gender: "",
        territory: "",
        indigenous_group: "",
        involved_type: "",
        age : ""
      };

      this.dialog = false;
    },
    async searchComuner() {
      this.showLoader();
      const response = await searchComuner(this.affected.document_number);
      if (isEmpty(response.data.results)) {
        Swal.fire(
            "Info",
            "No se encontraron resultados en el censo",
            "info"
        );
      } else {
        let results = response.data.results[0];

        this.affected.documentNumber = results.numeroidentificacion;
        this.affected.name = results.nombre1;
        this.affected.second_name = results.nombre2;
        this.affected.second_surname = results.apellido2;
        this.affected.lastname = results.apellido1;
        this.affected.gender = results.sexo;
        this.affected.territory = this.dataSource.data.Territorio.find(
            (x) => x.territorio_id === results.territorioorigen_actual
        );
        this.affected.indigenous_group = {};
      }

      this.hideLoader();
    },
  },
  computed: {
    ...mapState(["dataSource"]),
    requiredDocumentNumber(){

      if(this.affected.document_type === ''){
        return false;
      }

      const selectedDocumentType = this.dataSource.data.Tipoidentificacion.find(
          (x) => x.tipoidentificacion_id === this.affected.document_type
      );

      return selectedDocumentType.codigo !== "SI";
    },
    dynamicValidations(){
      return {
        affected: {
          document_number: this.requiredDocumentNumber ? {required, numeric} : {},
          document_type: {required},
          name: {required},
          lastname: {required},
          gender: {required},
          territory: {required},
          indigenous_group: {required},
          age : {required, numeric, maxLength: maxLength(3)}
        }
      }
    },
    validationErrors() {
      return {
        document_number: [
          ...(!this.$v.affected.document_number.required && this.requiredDocumentNumber
              ? ["Este campo es requerido"]
              : []),
          ...(!this.$v.affected.document_number.numeric && this.requiredDocumentNumber
              ? ["Solo se acceptan numeros"]
              : []),
        ],
        document_type: [
          ...(!this.$v.affected.document_type.required
              ? ["Este campo es requerido"]
              : []),
        ],
        name: [
          ...(!this.$v.affected.name.required
              ? ["Este campo es requerido"]
              : []),
        ],
        lastname: [
          ...(!this.$v.affected.lastname.required
              ? ["Este campo es requerido"]
              : []),
        ],
        gender: [
          ...(!this.$v.affected.gender.required
              ? ["Este campo es requerido"]
              : []),
        ],
        territory: [
          ...(!this.$v.affected.territory.required
              ? ["Este campo es requerido"]
              : []),
        ],
        indigenous_group: [
          ...(!this.$v.affected.indigenous_group.required
              ? ["Este campo es requerido"]
              : []),
        ],
        age: [
          ...(!this.$v.affected.age.required
              ? ["Este campo es requerido"]
              : []),
          ...(!this.$v.affected.age.numeric
              ? ["Solo se acceptan numeros"]
              : []),
          ...(!this.$v.affected.age.maxLength
              ? ["Solo se acceptan numeros de 3 digitos"]
              : []),
        ],
      };
    },
  },
};
</script>
