<template>
  <v-container>
    <v-row class="pl-3 pr-3">
      <v-col cols="12">
        <span class="green--text">Tipo de recepción</span>
        <v-autocomplete
          v-model="investigationType"
          :error-messages="validationErrors.investigationType"
          :items="selfJusticeReference.investigation_type"
          dense
          item-text="name"
          item-value="pk"
          placeholder="Seleccione un valor"
          solo
        >
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Affected.vue",
  props: ["newsList"],
  data() {
    return {
      investigationType: "",
      involved: [],
      headers: [
        {
          text: "TIPO DE RECEPCION",
          value: "name",
        },
      ],
    };
  },
  validations() {
    return {
      investigationType: { required },
    };
  },
  methods: {
    addAffected(involved) {
      this.involved.push(involved);
    },
    getData() {
      this.$v.$touch();
      if (this.$v.$invalid) return false;
      return {
        type_id: this.investigationType,
        involved: this.involved.map((affected) => {
          return {
            id: affected.id,
            document_number: affected.document_number,
            involved_type: affected.involved_type,
            document_type: affected.document_type,
            name: affected.name,
            second_name: affected.second_name,
            lastname: affected.lastname,
            second_surname: affected.second_surname,
            gender: affected.gender,
            territory_id: affected.territory.territorio_id,
            indigenous_group: affected.indigenous_group.pueblo_id,
          };
        }),
      };
    },
    clearForm(){
      this.investigationType = '';
    },
    getNews(type) {
      return this.newsList.filter((x) => x.investigation.type === type);
    },
  },
  computed: {
    filteredVictim() {
      return this.involved.filter((i) => {
        return i.involved_type === "VICTIM";
      });
    },
    filteredVictimize() {
      return this.involved.filter((i) => {
        return i.involved_type === "VICTIMIZER";
      });
    },
    filteredWitness() {
      return this.involved.filter((i) => {
        return i.involved_type === "WITNESS";
      });
    },
    ...mapState(["selfJusticeReference", "user"]),
    validationErrors() {
      const validationObject = {
        investigationType: [
          ...[
            !this.$v.investigationType.required
              ? "Seleccione un tipo de investigacion"
              : "",
          ],
        ],
      };

      for (const key in validationObject)
        validationObject[key] = validationObject[key].filter((x) => x !== "");

      return validationObject;
    },
  },
};
</script>

<style scoped></style>
