<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">Cambiar estado</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <p>Desea cambiar el estado a {{ getStateTitle(stateCode) }} ?</p>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
        <v-btn color="blue darken-1" text @click="changeState"> Aceptar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import selfJusticeStates from "@/enums/selfJusticeStates";
import { caseChangeState } from "@/services/SelfJustice";
import Swal from "sweetalert2";

export default {
  props: {
    stateCode: String,
    caseId: Number,
  },
  methods: {
    getStateTitle(code) {
      return {
        [this.states.CLOSE]: "CERRADO",
        [this.states.TRACKING]: "SEGUMIENTO",
        [this.states.CORRECTION]: "ARMONIZACION",
        [this.states.INVESTIGATION]: "INVESTIGACION",
      }[code];
    },
    async changeState() {
      try {
        this.showLoader();
        await caseChangeState({
          state_code: this.stateCode,
          case_id: this.caseId,
        });
        this.hideLoader();
        this.dialog = false;
        this.$emit("changeState");
      } catch (e) {
        console.error(e);
        await Swal.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
      await caseChangeState;
    },
    open() {
      this.dialog = true;
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    states() {
      return selfJusticeStates;
    },
  },
  name: "ChangeState.vue",
};
</script>

<style scoped></style>
