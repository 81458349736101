<template>
  <v-card dark color="primary" class="elevation-5 border-primary">
    <v-card-title class="text-h6">Investigación</v-card-title>
    <v-card-text class="white text--primary pt-1">
      {{ news.description }}
      <label class="d-block">Tipo de investigacion : {{news.investigation.type}}</label>
      <evidence-item v-for="evidence in news.evidences" :evidence-item="evidence" :key="evidence.id"></evidence-item>
    </v-card-text>
  </v-card>
</template>

<script>
import EvidenceItem from "@/components/commonComponents/EvidenceItem";
export default {
  components: {EvidenceItem},
  props: ["news"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>
