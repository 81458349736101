const eventTypes = {
  INVESTIGATION: "INVESTIGATION",
  CITATION: "CITATION",
  CAPTURE_ORDER: "CAPTURE_ORDER",
  PENALTIES: "PENALTIES",
  PENALTY_TRACKING: "PENALTY_TRACKING",
  REVIEW_REQUEST: "REVIEW_REQUEST",
  CLOSE: "CLOSE",
  ALERT: "ALERT",
};

Object.freeze(eventTypes);

export default eventTypes;
