<template>
  <v-card dark color="primary" class="elevation-5 border-primary">
    <v-card-title class="text-h6">ALERTA DE FUGA</v-card-title>
    <v-card-text class="white text--primary pt-1">
      <v-chip label small color="green" text-color="white">
        <v-avatar left>
          <v-icon>mdi-account-circle</v-icon>
        </v-avatar>
        {{ getInvolved(news.review_request_alert.person_involved) }}

      </v-chip>
      <br>
      {{ news.description }}
      <v-row>
        <v-col cols="12" v-for="evidence in news.evidences" :key="evidence.id">
          <evidence-item :evidence-item="evidence"></evidence-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import EvidenceItem from "@/components/commonComponents/EvidenceItem";
export default {
  components : {EvidenceItem},
  props: ["news", "involved"],
  data() {
    return {};
  },
    methods: {
      getInvolved(id) {
        const involved = this.involved.find((x) => x.id === id);
        if (involved)
          return `${involved.name} ${involved.lastname}`.toUpperCase();
        return "";
      }
    }
};
</script>

<style scoped></style>
